import React, {useState} from 'react'; 
import "./simpleInput.scss";

const SimpleInput = ({errorMessage, allowSpaces, errorRequire, label, validDate, ...rest}) => {
  const [isError, setError] = useState(false);
  const [isRequire, setIsRequire] = useState(false);
  const [inputValue, setInputValue] = useState('')

  React.useEffect(() => {
    if (rest.defaultValue) {
      setInputValue(rest.defaultValue)
    }
  }, [rest.defaultValue])

  const handleBlur = (evt) => {
    const {value, pattern, required, type, min, max} = evt.target;
    console.log('required', required);
    if (required && !value.trim()) {
      setIsRequire(true);
    }

    if (pattern && !(new RegExp(pattern).test(value))) {
      setError(true);
    }

    if (type === 'number') {
      setError(err => typeof Number(value) === 'number' &&  min < value && max > value ? false: true)
    }
  }

  const handleFocus = (evt) => {
    if (isError){
      setError(false);
    }

    if (isRequire) {
      setIsRequire(false)
    }
  }

  const errorClassName = isError ? 'errorMessage show' : 'errorMessage';
  const requireClasseName = isRequire ? 'errorRequire show' : 'errorRequire';
  const inputId =`input${rest.name}`;

  return (
    <div className="formField">  
      <div className="formFieldSimpleInput">       
        <label className={requireClasseName} htmlFor={inputId}>{errorRequire}</label>
        <label className={errorClassName} htmlFor={inputId}>{errorMessage}</label>
        <input
          onFocus={handleFocus}
          className="simpleInput"
          value={inputValue}
          onChange={(e) => setInputValue(!allowSpaces ? e.target.value.trim() : e.target.value)}
          onBlur={handleBlur}
          id={inputId}
          {...rest}
          autoComplete="off"
        />
        <label className="inputLabel" htmlFor={inputId}>{label}</label>
      </div>
    </div>
  );
};

export default SimpleInput;
