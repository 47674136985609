import React, {useState} from "react";
import "./simpleTextarea.scss";

const TextArea = ({label, errorRequire,  ...rest}) => {
  const [isRequire, setIsRequire] = useState(false);
  const handleBlur = (evt) => {
    const {value, required} = evt.target;

    if (required && !value.trim()){
      setIsRequire(true);
    }
  }

  const handleFocus = () => {
    if (isRequire) {
      setIsRequire(false)
    }
  }

  const textareaId = `textarea-${rest.name}`;
  const requireClasseName = isRequire ? 'errorRequire show' : 'errorRequire';

  return (
    <>
      <div className="formField">
        <div className="formFieldSimpleTextarea">
          <label className={requireClasseName} htmlFor={textareaId}>{errorRequire}</label>
          <textarea
            className="simpleTextarea"
            {...rest}
            id={textareaId}
            onBlur={handleBlur}
            onFocus={handleFocus}
          />
          <label className="inputLabel" htmlFor={textareaId}>{rest.placeholder}</label>
          </div>
      </div>
    </>
  );
};

export default TextArea;
