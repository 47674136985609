import React, { useRef, createRef, useEffect, useState } from "react";
import moment from "moment";
import ModalWindow from "../../ModalWindow/ModalWindow";
import { ImageModalContent } from "./";
import editMessageIcon from "../../../../style/icons/Chat/editMessageIcon.svg";
import deleteMessageIcon from "../../../../style/icons/Chat/deleteMessage.svg";
import checkSign from "../../../../style/icons/CheckSign/CheckSign.svg";
import checkSignReaded from "../../../../style/icons/CheckSign/CheckSignReaded.svg";
import checkSignGrayReaded from "../../../../style/icons/CheckSign/checkSign-grey.svg";
import playButton from "../../../../style/icons/Chat/playbtn.svg";
import pauseButton from "../../../../style/icons/Chat/pausebtn.svg";
import waveform from "../../../../style/icons/Chat/waveform.svg";

const MESSAGE_STATUS_READED = 2
const MESSAGE_STATUS_NOT_READED = 1

export const MessageComponent = ({
  item,
  deleteChatMessage,
  t,
  role,
  bothInChat,
  setMessageValue,
  chooseMessageId,
}) => {
  const [isOpenImageModal, toggleImageModal] = useState(false);
  const [isAudioPlay, setAudioPlay] = useState(false);
  const [currentAudioTime, setCurrentAudioTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const [animationDuration, setAnimationDuration] = useState("1");
  const audioFileRef = useRef();
  const prevAudioContainerRef = createRef();

  useEffect(() => {
    if (isAudioPlay && duration) {
      console.log({
        duration
      });
      const durationInS = moment(duration, 'mm:ss.S').format('s.S')
      console.log(durationInS);
      prevAudioContainerRef.current.style.animationDuration = `${durationInS}s`;
      prevAudioContainerRef.current.style.animationPlayState = "running";
    }
  }, [isAudioPlay, duration, prevAudioContainerRef]);


  const handleOnLoadedData = () => {
    console.log(audioFileRef);
    const durationInS = audioFileRef.current.duration 
    if (durationInS !== Infinity) {
      const durationFormat = moment(durationInS, 's.S').format('mm:ss.S')
      setDuration(durationFormat)
    } else {
      const durationFormat = moment(+item.customParameters.audioDuration).format("mm:ss")
      setDuration(durationFormat)
    }
  }

  useEffect(() => {
    if (isAudioPlay) {
      const interval = setInterval(() => {
        setCurrentAudioTime((prev) => prev + 1000);
      }, 1000);
      audioFileRef.current.onended = () => {
        setCurrentAudioTime(0);
      };
      return () => clearInterval(interval);
    }
  }, [isAudioPlay]);

  const editMessageHandler = () => {
    chooseMessageId(item._id);
    setMessageValue(item.message);
  };

  const deleteMessage = () => {
    deleteChatMessage(role, item._id);
  };

  let className =
    item.isSentByUser || item.chat_bot_doc === "1"
      ? "messageText occupantMessage"
      : "messageText";
  const time = moment(item.dateSent).format("HH:mm");
  const auxTime = moment(item.dateSent).format("mm:ss");

  if (
    item.type === "file" ||
    item.type === "image" ||
    item.type === "video" ||
    item.type === "voice"
  )
    className += " with-media";

  const getUrl = (url = undefined) => {
    if (
      item.type === "file" ||
      item.type === "image" ||
      item.type === "voice" ||
      item.type === "video"
    ) {
      return item.message;
    } else {
      return item.message;
    }
  };

  if (
    item.message === "Пропущенный звонок" ||
    item.message === "Пропущений дзвінок" ||
    item.message === "Missed call"
  ) {
    return (
      <div className="missedCallItem">
        <div className="message">{t("missedCall")}</div>
        <div className="msgTime">
          <div className="msgDate">{time}</div>
          <img
            className="checkSign"
            src={(bothInChat || item.status === MESSAGE_STATUS_READED) ? checkSignReaded : checkSign}
            alt="status-ico"
          />
        </div>
      </div>
    );
  }

  if (
    (item.type === "file" || item.type === "voice") &&
    className.includes("occupantMessage")
  ) {
    className += " with-media";
    if (item.type === "voice") {
      className += " unique-voice";
    }

    return (
      <div className={className}>
        <div className="messageContainer">
          {item.type.includes("video") ? (
            <video controls src={item.message} />
          ) : item.type.includes("image") ? (
            <>
              <img
                src={item.message}
                alt="occupantImage"
                height="300"
                onClick={() => toggleImageModal(true)}
              />
              <br />
              <a target="_blank" href={item.message} rel="noreferrer">
                {item.customParameters.file_name}
              </a>
              {isOpenImageModal && (
                <ModalWindow
                  isOpen={isOpenImageModal}
                  toggleOpen={() => toggleImageModal(false)}
                  t={t}
                >
                  <ImageModalContent
                    imgUrl={getUrl()}
                    t={t}
                    handleClose={() => toggleImageModal(false)}
                  />
                </ModalWindow>
              )}
            </>
          ) : item.type.includes("voice") ? (
            <>
              <div
                ref={prevAudioContainerRef}
                className="animationMessage"
              ></div>
              <audio
                onPause={() => setAudioPlay(false)}
                onEnded={() => {
                  prevAudioContainerRef.current.classList.remove(
                    "animationMessage"
                  );
                  setAudioPlay(false);
                }}
                onLoadedMetadata={handleOnLoadedData}
                onError={() => setAudioPlay(false)}
                preload="metadata"
                ref={audioFileRef}
                src={item.message}
              />
              <div className="audioFile">
                <img
                  className="playAudioImg"
                  src={isAudioPlay ? pauseButton : playButton}
                  alt=""
                  onClick={() => {
                    prevAudioContainerRef.current.classList.add(
                      "animationMessage"
                    );
                    setAudioPlay((prevState) => !prevState);
                    if (isAudioPlay) {
                      audioFileRef.current.pause();
                      prevAudioContainerRef.current.style.animationPlayState =
                        "paused";
                    } else {
                      prevAudioContainerRef.current.style.animationPlayState =
                        "running";
                      audioFileRef.current.play();
                    }
                  }}
                />
                <div className="waveContainer">
                  <img className="wavePicture" src={waveform} alt={"etete1"} />
                  <span className="audioDuration">
                    {moment(currentAudioTime).format("mm:ss")}/{duration}
                  </span>
                </div>
              </div>
            </>
          ) : (
            <a target="_blank" href={item.message} rel="noreferrer">
              {item.customParameters.file_name}
            </a>
          )}
          <div className="msgTime">
            <div className="msgDate">{time}</div>
            {item.type === "voice" ? (
              <img
                className="checkSign"
                src={(bothInChat || item.status === MESSAGE_STATUS_READED) ? checkSignReaded : checkSign}
                alt="status-ico"
              />
            ) : (
              <img
                className="checkSign"
                src={(bothInChat || item.status === MESSAGE_STATUS_READED) ? checkSignGrayReaded : checkSign}
                alt="status-ico"
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="messageContainer">
        {item.type === "file" ? (
          <a target="_blank" href={item.message} rel="noreferrer">
            {item.customParameters && item.customParameters.file_name}
          </a>
        ) : item.type === "image" ? (
          <>
            <img
              style={{ cursor: "pointer" }}
              src={item.message}
              alt="userImage"
              height="300"
              onClick={() => toggleImageModal(true)}
            />
            <br />
            <a target="_blank" href={item.message} rel="noreferrer">
              {item.customParameters && item.customParameters.file_name}
            </a>
            {isOpenImageModal && (
              <ModalWindow
                isOpen={isOpenImageModal}
                toggleOpen={() => toggleImageModal(false)}
                t={t}
              >
                <ImageModalContent
                  t={t}
                  imgUrl={getUrl()}
                  handleClose={() => toggleImageModal(false)}
                />
              </ModalWindow>
            )}
          </>
        ) : item.type === "video" ? (
          <video controls src={item.message} />
        ) : item.type.includes("voice") ? (
          <>
            <div ref={prevAudioContainerRef} className="animationMessage"></div>
            <audio
              onLoadedMetadata={handleOnLoadedData}
              onPause={() => setAudioPlay(false)}
              onEnded={() => {
                prevAudioContainerRef.current.classList.remove(
                  "animationMessage"
                );
                setAudioPlay(false);
              }}
              
              onError={() => setAudioPlay(false)}
              preload="metadata"
              ref={audioFileRef}
              src={item.message}
            />
            <div className="audioFile">
              <img
                className="playAudioImg"
                src={isAudioPlay ? pauseButton : playButton}
                alt=""
                onClick={() => {
                  prevAudioContainerRef.current.classList.add(
                    "animationMessage"
                  );
                  setAudioPlay((prevState) => !prevState);
                  if (isAudioPlay) {
                    audioFileRef.current.pause();
                    prevAudioContainerRef.current.style.animationPlayState =
                      "paused";
                  } else {
                    prevAudioContainerRef.current.style.animationPlayState =
                      "running";
                    audioFileRef.current.play();
                  }
                }}
              />
              <div className="waveContainer">
                <img className="wavePicture" src={waveform} alt={"etete1"} />
                <span className="audioDuration">
                  {moment(currentAudioTime).format("mm:ss")}/{duration}
                </span>
              </div>
            </div>
          </>
        ) : (
          <span>{item.message}</span>
        )}
        <div className="msgTime">
          <div className="msgDate">{time}</div>
          {item.isSentByUser ? (
            <img
              className="checkSign"
              src={(bothInChat || item.status === MESSAGE_STATUS_READED) ? checkSignGrayReaded : checkSign}
              alt="status-ico"
            />
          ) : (
            <img
              className="checkSign"
              src={(bothInChat || item.status === MESSAGE_STATUS_READED) ? checkSignReaded : checkSign}
              alt="status-ico"
            />
          )}
          {item.status === MESSAGE_STATUS_NOT_READED && !bothInChat && !item.isSentByUser && (
            <>
              {item.type === "file" ||
                item.type === "image" ||
                item.type === "voice" || (
                  <img
                    className="editMessageIcon"
                    src={editMessageIcon}
                    alt="edit icon"
                    onClick={editMessageHandler}
                  />
                )}
              <img
                className="deleteMessageIcon"
                src={deleteMessageIcon}
                alt="delete icon"
                onClick={deleteMessage}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
