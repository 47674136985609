import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

export default {
  getPromocodesWithFilters: async (filterString) => {
    const res = await axios.get(`/admin/promocodes?${filterString}`);
    return res && res.data;
  },
  getAllPromoCodes: async (numberOfPage) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/promocodes${
        numberOfPage ? `?page=${numberOfPage}` : ""
      }`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },

  deletePromocode: async (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/promocodes/${id}`,
      headers: setHeader(),
    };
    return axios(config).then(({data}) => data)
  },

  getFilteredPromocodes: async (
    status = "",
    type = "",
    dateStart = "",
    dateEnd = "",
    companyId = "",
    numberOfPage = "1"
  ) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/promocodes?filter[status]=${status}&filter[type]=${type}&filter[activatedFrom]=${dateStart ?? ''}&filter[activatedTo]=${dateEnd ?? ''}&filter[insuranceCompany]=${companyId}&page=${numberOfPage}`,
      headers: setHeader(),
    };

    const res = await axios(config);
    return res && res.data;
  },

  downloadPromocodesSCV: async (
    {
      status = "",
      type = "",
      dateStart = "",
      dateEnd = "",
      companyId = "",
    },
    token
  ) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/promocodes/download?filter[status]=${status}&filter[type]=${type}&filter[activatedFrom]=${dateStart}&filter[activatedTo]=${dateEnd}&filter[insuranceCompany]=${companyId === 'none' ? '' : companyId}&bearer=${token}`,
      headers: setHeader(),
    };
    window.open(
      `${TEST_API}/admin/promocodes/download?filter[status]=${status}&filter[type]=${type}&filter[activatedFrom]=${dateStart}&filter[activatedTo]=${dateEnd}&filter[insuranceCompany]=${companyId === 'none' ? '' : companyId}&bearer=${token}`,
      "_blank"
    );
    const res = await axios(config);
    return res && res.data;
  },

  generateNewPromocodes: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/promocodes/generate`,
      headers: setHeader(),
      data,
    };
    return axios(config)
      .then((res) => res && res.data)
    // const res = await axios(config);
    // return res && res.data;
  },
};
