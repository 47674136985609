import { GET_ALL_FAVORITE_PATIENTS } from "./types";
import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = null;

const patientsReducer = createReducer(initialState)({
  [types.GET_ALL_PATIENTS]: (state, { items, total_item_count }) => ({
    ...state,
    items,
    total_item_count,
  }),
  [types.GET_PATIENT_CARD]: (state, { patientCard }) => ({
    ...state,
    patientCard,
  }),
  [types.GET_THE_PATIENT]: (state, { patient }) => ({
    ...state,
    patient,
  }),
  [types.GET_THE_PATIENT_ANAMNESIS]: (state, { anamnesis }) => ({
    ...state,
    anamnesis,
  }),
  [types.GET_PATIENT_CARDS]: (state, { cards }) => ({
    ...state,
    cards,
  }),
  [types.RESET_PATIENT]: (state) => ({
    ...state,
    patient: {},
  }),
  [types.GET_THE_PATIENT_APPOINTMENTS]: (state, { appointments }) => ({
    ...state,
    appointments,
  }),
  [types.SEARCH_PATIENTS]: (state, { searchList, searchKey }) => ({
    ...state,
    searchList,
    searchKey,
  }),
  [types.RESET_PATIENTS]: (state) => null,

  [types.END_OF_CONSULTATION]: (
    state,
    { endOfConsultation, reasonOfEndingConsultation }
  ) => ({
    ...state,
    endOfConsultation,
    reasonOfEndingConsultation,
  }),

  [types.CANCEL_OF_CONSULTATION]: (state, { cancelledConsultation }) => ({
    ...state,
    cancelledConsultation,
  }),

  [types.GET_RELATIONS]: (state, { data: { items } }) => ({
    ...state,
    relations: items,
  }),
  [types.GET_ALL_FAVORITE_PATIENTS]: (state, data) => ({
    ...state,
    ...data,
  }),
});

export default patientsReducer;
