export const GET_CHAT_MESSAGES_TEMPLATES = "GET_CHAT_MESSAGES_TEMPLATES";
export const SET_CHAT_HISTORY = "SET_CHAT_HISTORY";
export const START_MEDIA_RECORDER = "START_MEDIA_RECORDER";
export const STOP_MEDIA_RECORDER = "STOP_MEDIA_RECORDER";
export const SEND_MEDIA_RECORDER = "SEND_MEDIA_RECORDER";
export const DELETE_MEDIA_RECORDER = "DELETE_MEDIA_RECORDER";
export const SET_NEW_MESSAGE = "SET_NEW_MESSAGE";
export const SET_PAGIN_CHAT_HISTORY = "SET_PAGIN_CHAT_HISTORY";

export const WS_CONNECT = "WS_CONNECT";
export const WS_DISCONNECT = "WS_DISCONNECT";

export const WS_CALL_CONNECT = "WS_CALL_CONNECT";
export const WS_CALL_DISCONNECT = "WS_CALL_DISCONNECT";
export const MUTE_VIDEO_CHAT_CALL = "MUTE_VIDEO_CHAT_CALL";

export const MUTE_AUDIO_CHAT_CALL = "MUTE_AUDIO_CHAT_CALL";
export const MAKE_CHAT_CALL = "MAKE_CHAT_CALL";
export const STOP_CHAT_CALL = "STOP_CHAT_CALL";
export const SEND_CHAT_MESSAGE = "SEND_CHAT_MESSAGE";
export const SET_TOTAL_CHAT_MEMBERS = "SET_TOTAL_CHAT_MEMBERS";
export const DELETE_CHAT_MESSAGE = "DELETE_CHAT_MESSAGE";
export const EDIT_CHAT_MESSAGE = "EDIT_CHAT_MESSAGE";
export const START_CONSULTATION = "START_CONSULTATION";
export const START_AUDIO_CALL = "START_AUDIO_CALL";
export const START_VIDEO_CALL = "START_VIDEO_CALL";
export const RESET_CALL = "RESET_CALL";
export const END_VIDEO_CALL = "END_VIDEO_CALL";
export const END_AUDIO_CALL = "END_AUDIO_CALL";
export const READ_MESSAGES_LIST = "READ_MESSAGES_LIST";
export const IS_READ_MESSAGES_LIST = "IS_READ_MESSAGES_LIST";
export const CLEAR_HISTORY_CHAT = "CLEAR_HISTORY_CHAT";
export const SEND_REJECT_MESSAGE = "SEND_REJECT_MESSAGE";
export const SET_REJECTED = "SET_REJECTED";
export const SET_NEED_SCROLLING = "SET_NEED_SCROLLING";
export const CHANGE_CONNECT_STATUS_CHAT = "CHANGE_CONNECT_STATUS_CHAT";
