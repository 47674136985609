import axios from "axios";
import {
  setHeader,
  TEST_API,
  SIGNALING_SERVER,
  PORT_CHAT_SOCKET,
  PORT_SUPPORT_SOCKET,
  PORT_ATTENDING_DOCTOR,
} from "../index";
import { handleStatusUploadFile } from "../../helpers/http";

export default {
  getChatMessagesTemplates: (data) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/chat-template-groups`,
      headers: setHeader("ua"),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  deviceCall: ({ appointmentId, data }) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/devices/call/${appointmentId}`,
      headers: setHeader("ua"),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  chatAudioMsgUpload: ({ id, file }) => {
    let formData = new FormData();
    formData.append("audioFile", file);
    return axios.post(
      `${TEST_API}/admin/devices/uploads/audio/${id}`,
      formData,
      {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  chatOperatorAudioMsgUpload: ({ userId, file }) => {
    let formData = new FormData();
    formData.append("file", file);
    return axios.post(`${TEST_API}/admin/support/${userId}/upload`, formData, {
      headers: {
        ...setHeader(),
        "Content-Type": "multipart/form-data",
      },
    });
  },

  chatFileUpload: ({ id, file, setUploadStatus = () => {} }) => {
    let formData = new FormData();
    formData.append("file", file);
    return axios
      .post(`${TEST_API}/admin/chats/${id}/upload`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (evt) => setUploadStatus(handleStatusUploadFile(evt)),
      })
      .then((res) => res && res.data);
  },
  chatOperatorFileUpload: ({ id, file, setUploadStatus = () => { } }) => {
    let formData = new FormData();
    formData.append("file", file);
    return axios
      .post(`${TEST_API}/admin/support/${id}/upload`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (evt) => setUploadStatus(handleStatusUploadFile(evt)),
      })
      .then((res) => res && res.data);
  },

  getChatHistory: (id, page) => {
    let config = {
      method: "GET",
      baseURL: `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/chat/history/${id}?page=${
        page ? page : "0"
      }`,
      headers: setHeader("ua"),
    };
    return axios(config).then((res) => {
      if (res && res.status === 200) {
        return res && res.data;
      }
      return { data: { item: [] } };
    });
  },

  getAttendingDoctorChatHistory: (
    patientCardId = Number,
    doctorId = Number
  ) => {
    let config = {
      method: "GET",
      baseURL: `${SIGNALING_SERVER}${PORT_ATTENDING_DOCTOR}/attendingDoctor/history/patientCard/${patientCardId}/doctor/${doctorId}`,
      headers: setHeader("ua"),
    };
    return axios(config).then((res) => {
      return res && res.data;
    });
  },

  getOperatorChatHistory: (id, page) => {
    let config = {
      method: "GET",
      baseURL: `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/support/history/${id}?page=${
        page ? page : "0"
      }`,
      headers: setHeader("ua"),
    };
    return axios(config).then((res) => res && res.data);
  },

  editChatMessage: (role, messageID, messageValue = "") => {
    const data = { message: messageValue };
    let URL;
    if (role === "operator") {
      URL = `${SIGNALING_SERVER}${PORT_SUPPORT_SOCKET}/appointment/chat/operator/${messageID}`;
    }
    if (role === "doctor") {
      URL = `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/chat/${messageID}`;
    }
    let config = {
      method: "patch",
      baseURL: URL,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },

  deleteChatMessage: (role, messageID = Number, isAttendingDoctor) => {
    let URL;
    if (role === "operator") {
      URL = `${SIGNALING_SERVER}${PORT_SUPPORT_SOCKET}/appointment/chat/operator/${messageID}`;
    } else if (isAttendingDoctor === true && role === "doctor") {
      URL = `${SIGNALING_SERVER}${PORT_ATTENDING_DOCTOR}/attendingDoctor/chat/${messageID}`;
    } else {
      URL = `${SIGNALING_SERVER}${PORT_CHAT_SOCKET}/appointment/chat/${messageID}`;
    }
    let config = {
      method: "DELETE",
      baseURL: URL,
      headers: setHeader(),
    };
    return axios(config).then((res) => res && res.data);
  },
};
