import moment from "moment";
import React from "react";
import patientLogo from "../../../style/icons/Subscription/patient_active.svg";
import Paginator from "../Paginator/Paginator";
import Spinner from "../ui/Spinner/Spinner";

import "./FavoritesPatientsList.scss";
import { useHistory } from "react-router-dom";

const FavoritePatientsList = ({
  listItems = [Array(Object)],
  loading = Boolean,
  role = String,
  current_page = Number,
  page_count,
  setPagination,
  setLoading,
  t,
}) => {
  const history = useHistory();
  return (
    <div className="favoritesItemsWrapper">
      {loading && (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      )}
      <div className="favoritesItemsContainer">
        {listItems &&
          !loading &&
          listItems.map((item = Object, i) => {
            return (
              <div
                key={i}
                className="itemContainer"
                onClick={() => {
                  history.push(
                    `/home/${role}/client/${item.patientId}/${item.id}?user=${item.patientId}`
                  );
                  localStorage.setItem("isPersonalDoctor", "true");
                }}
              >
                <img
                  className="patientFavorite"
                  src={item.photoUrl === "" ? patientLogo : item.photoUrl}
                  alt="user-avatar"
                />
                <p className="patientTitle">Пациент</p>
                <p className="patientFullName">{item.fullName}</p>
                <div className="patientFooter">
                  <div className="idBlock">
                    <span className="itemIdTitle">ID</span>
                    <span className="itemIdValue">#{item.id}</span>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      {listItems && page_count !== 0 && (
        <Paginator
          current_page={current_page}
          setLoading={setLoading}
          page_count={page_count}
          scrollElement="consultationItemsContainer"
          role={role}
          setPagination={setPagination}
          // intervalDisabled
        />
      )}
    </div>
  );
};

export default FavoritePatientsList;
