import axios from "axios";
import { setHeader } from "../index";
import { TEST_API } from "../index";

import { store } from "../../";
import { setNetworkError } from "../../redux/networkError/actions";

export default {
  getSubs: (numberOfPage = 1) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/subscriptions?page=${numberOfPage}&perPage=12`,
      headers: setHeader(),
    };

    return axios(config).then((res) => !res.code && res);
  },
  createSub: async (body) => {
    return await fetch(`${TEST_API}/admin/subscriptions`, {
      method: "POST",
      headers: setHeader(),
      body,
    }).then((res) => res.statusText === "Created" && res);
  },
  createCompany: async (body) => {
    return await fetch(`${TEST_API}/admin/companies`, {
      method: "POST",
      headers: setHeader(),
      body,
    }).then((res) => res && res.json());
  },
  editCompany: async (id, body) => {
    const res = await axios.put(`${TEST_API}/admin/companies/${id}`, body, {
      headers: {
        ...setHeader()
      }
    })
    return res && res.data
  },
  deleteCompany: async (id) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/companies/${id}`,
      headers: setHeader()
    }
    return axios(config)
  },
  deleteSub: async (obj) => {
    let config = {
      method: "DELETE",
      baseURL: `${TEST_API}/admin/companies/${obj.company}/patients/${obj.subscriber}`,
      headers: setHeader()
    }
    return axios(config).then(res => res && res.data)
  },
  addCompanyPhoto: async (id, file) => {
    let formData = new FormData();
    formData.append("imageFile", file);
    return axios
      .post(`${TEST_API}/admin/companies/${id}/uploadPhoto`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },
  getSubscribers: ({ filterMode, id, numberOfPage }) => {
    let config = {
      method: "GET",
      baseURL: `${TEST_API}/admin/companies/${id}/patients${
        filterMode
          ? numberOfPage
            ? `?filter[${filterMode}]=true&page=${numberOfPage}`
            : `?filter[${filterMode}]=true`
          : numberOfPage
          ? `?page=${numberOfPage}`
          : ""
      }`,
      headers: setHeader(),
    };

    return axios(config).then((res) => !res.code && res && res.data);
  },
  sendPushNotifications: (data) => {
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/subscriptions/topic/push`,
      headers: setHeader(),
      data,
    };
    return axios(config).then((res) => res && res.data);
  },
  updateCompanyPhoto: async (companyId, file) => {
    let formData = new FormData();
    formData.append("imageFile", file);
    return axios
      .post(`${TEST_API}/admin/companies/${companyId}/uploadPhoto`, formData, {
        headers: {
          ...setHeader(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res && res.data);
  },
  upload: async (file, companyId) => {
    let formData = new FormData();
    formData.append("cardsFile", file);
    let config = {
      method: "POST",
      baseURL: `${TEST_API}/admin/companies/${companyId}/import/async`,
      headers: {
        ...setHeader(),
        "Content-Type": "multipart/form-data",
      },
      data: formData
    }
    try {
      const { data } = await axios(config)
      // const { response } = await axios.get(`${TEST_API}/admin/companies/${companyId}/${data.entity.id}/statistic`, {
      //   headers: setHeader()
      // })
      return data
    } catch(e) {
      console.log('ERROR');
    }
  },
};
