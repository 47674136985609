import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { reset } from "redux-form";
import {useHistory} from "react-router-dom"
import { useTranslation } from "react-i18next";

import {
  getAllEmployees,
  setFilterMode,
  setPagination,
  getDoctorsByFilter,
  getDoctorsByFilterPrimary,
  getEmployeesByFilter,
} from "../../../redux/employees/actions";

import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";
import specEdit from "../../../style/icons/PersonEdit/person-edit.svg";
import docIcon from "../../../style/icons/DocIcon/doc.svg";
import activeOperatorIcon from "../../../style/icons/Operator/operator-blue.svg";
import Spinner from "../ui/Spinner/Spinner";
import Pagination from "@material-ui/lab/Pagination";
import "./employeeList.scss";
import moment from "moment";
import { useStyles } from "./styles";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { filters } from "./filters";
import TagsInput from "../TagsInput/TagsInputNew"
import { getAdminsCount, getCurrentPage, getDoctorsCount, getEmployeeCount, getEmployeeItems, getOperatorsCount, getPageCount } from "../../../redux/employees/selectors";

const EmployeeList = ({
  setEdit,
  setInitalValues,
  total_item_count,
  countDoctors,
  countOperators,
  items = [],
  page_count,
  specialities,
  formInitialValues,
  edit,
  setAddingImg,
  setBlob,
  currentPage = "",
  currentFilter = "",
  currentTags = "",
  loading,
}) => {
  const history = useHistory()
  const { t } = useTranslation();
  const classes = useStyles()

  const handleChangePage = (_, numberOfPage) => {
    history.push(`${history.location.pathname}${history.location.search.replace(`page=${currentPage}`, `page=${numberOfPage}`)}`)
  };

  const handleClickFilter = (filter) => {
    return () => {
      if(loading) return
      history.push(`${history.location.pathname}${history.location.search.replace(`filter=${currentFilter}`, `filter=${filter}`)}`)
    }
  }

  const handleChangeSpecialties = (choossenArr) => {
    if(loading) return
    const newTags = choossenArr.join(",")
    history.push(`${history.location.pathname}${history.location.search.replace(`specialties=${currentTags}`, `specialties=${newTags}`)}`)
  }

  const getFilterCounts = (filter) => {
    switch (filter) {
      case "operators": return countOperators
      case "doctors": return countDoctors
      case "all": return total_item_count
    }
  }

  const tags = (currentTags && currentTags.split(",").map(tag => Number(tag))) || []
  const selectedSpecialties = (specialities && specialities.filter(el => tags.includes(el.id))) || []

  return (
    <>
      <div className="employeeListContainer">
        <div className="titleWithFilters">
          <div className="title">{t("employeesFlowTitle")}</div>

          <div className="filters">
            {filters.map((item) => (
              <div
                className={`filter ${currentFilter === item.filter && items && items.length > 0? "active": ""}`}
                onClick={handleClickFilter(item.filter)}
              >{t(item.trans)} {getFilterCounts(item.filter)}</div>
            ))}
          </div>

          
          <TagsInput
            disabled={currentFilter !== "doctors" || (items && items.length < 1) || loading}
            options={currentFilter === "doctors" ? specialities : []}
            onChange={handleChangeSpecialties}
            currentTags={selectedSpecialties}
            placeholder={t("placeholderSpecialty")}
          />
        </div>
        <div
          className={`employeeItemsContainer ${items && items.length > 0 ? "" : "empty"
            }`}
        >
          {loading && (
            <div className="absoluteCenter">
              <Spinner />
            </div>
          )}

          {!loading &&
            items &&
            items.length &&
            page_count &&
            items.length >= 1 ? (
            items.map((item) => (
              <div
                key={item.id}
                className={`employeeItem ${edit && item.id === formInitialValues?.editingId
                    ? "editItem"
                    : edit
                      ? "editMode"
                      : ""
                  }
                ${item.isBlocked || (item.employee && item.employee.isBlocked)
                    ? "locked"
                    : ""
                  }`}
              >
                <div className="id">
                  <div className="title">ID</div>
                  <div className="idInfo">#{item.id}</div>
                </div>
                <div className="specialization">
                  <div className="title">
                    {item.doctor || item.specialty
                      ? t("specializationTitle")
                      : t("operator")}
                  </div>
                  <div className="specializationInfo">
                    <img
                      src={
                        item.photoUrl ||
                        (item.employee && item.employee.photoUrl) ||
                        (item.doctor || item.specialty
                          ? docIcon
                          : activeOperatorIcon)
                      }
                      alt=""
                    />
                    <div className="details">
                      <span className="name">
                        {item.fullName ||
                          (item.employee && item.employee.fullName)}
                      </span>
                      {((item.doctor && item.doctor.educationSpecialty) ||
                        (item && item.educationSpecialty)) && (
                          <span className="spec">
                            {currentFilter === "all"
                              ? item.doctor &&
                              item.doctor.specialty.map((el, i) => (
                                <span key={i}>{`${i >= 1 ? ", " : ""} ${el.title
                                  }`}</span>
                              ))
                              : item.specialty &&
                              item.specialty.map((el, i) => (
                                <span key={i}>{`${i >= 1 ? ", " : ""} ${el.title
                                  }`}</span>
                              ))}
                          </span>
                        )}
                    </div>
                  </div>
                </div>
                <div className="phone">
                  <div className="title">{t("phone")}</div>
                  <div className="phoneInfo">
                    <div className="phoneNumber">
                      {item.phone ||
                        (item.employee && item.employee.phone) ||
                        ""}
                    </div>
                  </div>
                </div>
                <div className="actions">
                  <img
                    src={specEdit}
                    alt="edit-icon"
                    onClick={() => {
                      setEdit();
                      reset();
                      resetInitialValues();
                      setAddingImg(false);
                      setBlob(null);
                      setInitalValues({
                        ...item,
                        ...item.employee,
                        ...item.doctor,
                        employeeID:
                          (item.employee && item.employee.id) || item.id,
                        doctorId: item.doctor && item.doctor.id,
                        editingId: item.id,
                        fillType: { value: "hand" },
                        employeeType:
                          item.doctor || item.specialty
                            ? { value: "doc" }
                            : { value: "operator" },
                        sex:
                          item.gender === 0 ||
                            (item.employee && item.employee.gender === 0)
                            ? t("maleSex")
                            : t("femaleSex"),

                        photo: {
                          cropImg:
                            item.photoUrl ||
                            (item.employee && item.employee.photoUrl),
                        },
                        insuranceCompany:
                          item &&
                          item.insuranceCompany &&
                          item.insuranceCompany.title,
                        isAdmin: item.roles
                          ? item.roles.some((role) => role === "ROLE_ADMIN")
                          : false,
                        birthDate: moment(item?.birthDate).format("DD/MM/YYYY"),
                      });
                    }}
                  />
                </div>
                {(item.isBlocked ||
                  (item.employee && item.employee.isBlocked)) && (
                    <div className="locker" />
                  )}
              </div>
            ))
          ) : (
            <>
              {!loading && (
                <p className="employeeEmptyText">{t("emptyList")}</p>
              )}
            </>
          )}
        </div>
        <div className={classes.root}>
          <Pagination
            classes={{ ul: classes.ul }}
            count={page_count}
            color="primary"
            page={+currentPage}
            defaultPage={1}
            size="large"
            onChange={handleChangePage}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  items: getEmployeeItems(state),
  total_item_count: getEmployeeCount(state),
  countDoctors: getDoctorsCount(state),
  countOperators: getOperatorsCount(state),
  countAdmins: getAdminsCount(state),
  page_count: getPageCount(state),
  current_page: getCurrentPage(state),
  formInitialValues: state.formInitialValues,
});

export default connect(mapStateToProps, {
  getAllEmployees,
  setInitalValues,
  setFilterMode,
  setPagination,
  getDoctorsByFilter,
  getDoctorsByFilterPrimary,
  getEmployeesByFilter,
})(EmployeeList);
