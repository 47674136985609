import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import {
  getAllCompanies,
  setPagination,
} from "../../../redux/сompanies/actions";

import Paginator from "../Paginator/Paginator";

import "./DivisionList.scss";

const DivisionList = ({
  setPagination,
  getAllCompanies,
  companies,
  current_page,
  page_count,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getAllCompanies();
  }, []);

  const DivisionItem = ({ title }) => (
    <div className="item-container">
      <p>{title}</p>
    </div>
  );

  return (
    <>
      <div className="page-title">
        <span className="title">{t("divisions")}</span>
      </div>
      <div className="companiesListContainer">
        {companies &&
          companies.length &&
          companies.map((company, i) => (
            <DivisionItem title={company.title} key={i} />
          ))}
      </div>
      {companies !== [] && page_count !== 0 && (
        <Paginator
          current_page={current_page}
          setPagination={setPagination}
          setLoading={setLoading}
          page_count={page_count}
          intervalDisabled
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  companies: state && state.companies && state.companies.items,
  current_page: state && state.companies && state.companies.current_page,
  page_count: state && state.companies && state.companies.page_count,
});

export default connect(mapStateToProps, { getAllCompanies, setPagination })(
  DivisionList
);
