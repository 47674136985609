import React, { useState, useEffect, useRef } from "react";
import ScrollArea from "react-scrollbar";
import cm from "classnames";
import shevron from "../../../../style/icons/ShevronDown/shevron-down.svg";
import close from "../../../../style/icons/CloseInGreyCircle/close.svg";
import './simpleSelect.scss';
import "../formComponents.scss";

const SimpleSelect = ({
  options = [],
  defaultValue,
  placeholder,
  setSelectedValue,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if(setSelectedValue) return
    inputRef.current.value = ''
  }, [options])

  const handleReset = () => {
    setIsActive(false);
    inputRef.current.value = '';
  }

  useEffect(()=> {
    if (defaultValue) {
      inputRef.current.value = defaultValue;
    }
  }, [defaultValue]);

  const clearSelected = ()=> {
    inputRef.current.value = '';
    setIsActive(true);
  }

  const toggleState = () => {
    setIsActive(!isActive)
  }
  const handleChange = (value) => {
    inputRef.current.value = value;
    if(setSelectedValue) setSelectedValue(value)
    setIsActive(false);
  };

  const openList = () => setIsActive(!isActive);
  const toggleActive = isActive => !isActive;
  const selected = inputRef.current && inputRef.current.value.toString();
  const labelValue = selected
    ? options.find(_ => _.value.toString() === selected) && options.find(_ => _.value.toString() === selected).label
    : '';

  return (
    <div className="formField">
      <div className="fieldSimpleSelect">
        <div onClick={openList} >
        <input
          disabled
          className="simpleInput"
          type="input"
          placeholder={placeholder}
          onChange={handleChange}
          onReset={handleReset}
          ref={inputRef}
          {...rest}
        />
        {labelValue && <div className="value">
          {labelValue}
          </div>}
        </div>
        {selected?
          <img
            className="circle"
            src={close}
            alt=""
            onClick={clearSelected}
          /> :
          <img
            className={`shevron ${isActive ? "active" : ""}`}
            src={shevron}
            alt=""
            onClick={toggleActive}
          />
        }
        <div className={"wrapperOptions"}>
          {isActive && (
            <ScrollArea speed={0.1} className={"area"} horizontal={false}>
              <div className={"options"}>
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={cm("option", {
                      active: option.value === selected,
                    })}
                    onClick={() => handleChange(option.value)}
                  >
                    {option.label}
                  </div>
                ))}
              </div>
            </ScrollArea>
          )}
        </div>
      </div>
    </div>
  );
};
export default SimpleSelect;
