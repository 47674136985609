import * as types from "./types";
import api from "../../api";
import moment from "moment";


export const getEmployeesByFilter = ({filter, page, specs = "", firstTime}) => async dispatch => {
  let data = {}
  let counters = {}
  const isDoctor = filter === "doctors";
  const isOperator = filter === "operators";
  const isAll = filter === "all";
  try {
    if (isDoctor) {
      const specialties = specs && specs.split(",")
      const filterString = specialties?.length ? (specialties.length === 1 ? `filter[specialty][${specialties[0]}]` : specialties.map(el => `filter[specialty][${el}]`).join("&")) : ""
      data = await api.employees.getAllDoctors(page, filterString, 10)
      if(firstTime) counters = await api.employees.getAllEmployees(1)
    }
    if (isOperator) {
      data = await api.employees.getAllOperators(page)
    }
    if (isAll) {
      data = await api.employees.getAllEmployees(page)
    }
    const dispatchBody = {
      type: types.GET_ALL_EMPLOYEES,
      items: data?.data?.items || [],
      total_item_count: data?.count?.COUNT_TOTAL || "0",
      current_page: data?.data?.current_page || 1,
      page_count: data?.data?.page_count || 1,
      countDoctors: data?.count?.COUNT_DOCTOR || "0",
      countOperators: data?.count?.COUNT_OPERATOR || "0",
      totalCount: data?.count?.COUNT_TOTAL || "0",
      filterMode: filter || "all",
    }
    if (isDoctor) {
      delete dispatchBody.countOperators;
      delete dispatchBody.totalCount;
      delete dispatchBody.total_item_count;
      dispatchBody.countDoctors = data?.data?.total_item_count
      if (firstTime) {
        dispatchBody.total_item_count = counters?.count?.COUNT_TOTAL;
        dispatchBody.countOperators = counters?.count?.COUNT_OPERATOR;
      }
    }
    dispatch(dispatchBody)
  } catch (e) {
    console.log(e.message);
  }
}


export const getAllEmployees = (filterMode) => async (dispatch) => {
  if (filterMode === "all" || filterMode === undefined) {
    return await api.employees.getAllEmployees().then(async (responce) => {
      let countOperators = await api.employees
        .getAllOperators()
        .then((res) => res && res.data && res.data.total_item_count);

      let countAdmins = 0;

      let countDoctors = await api.employees
        .getAllDoctors()
        .then((res) => res && res.data && res.data.total_item_count);

      return dispatch({
        type: types.GET_ALL_EMPLOYEES,
        ...(responce && responce.data),
        countOperators,
        countDoctors,
        countAdmins,
        filterMode: filterMode || "all",
      });
    });
  }
  if (filterMode === "doctors") {
    return await api.employees.getAllDoctors().then((res) =>
      dispatch({
        type: types.GET_ALL_DOCTORS,
        ...res.data,
        filterMode,
      })
    );
  }
  if (filterMode === "operators") {
    return api.employees.getAllOperators().then((res) =>
      dispatch({
        type: types.GET_ALL_OPERATORS,
        filterMode,
        ...res.data,
        countOperators: res.data.total_item_count,
      })
    );
  }
};

export const setFilterMode = (filterMode) => {
  return {
    type: types.SET_FILTER_MODE_EMPLOYEE,
    filterMode,
  };
};

export const createOperator = (data) => (dispatch) => {
  let operator = data;
  const role = () => {
    if (operator.isAdmin) {
      return "ROLE_INSURANCE_ADMIN";
    } else if (!operator.isAdmin && operator.insuranceCompany) {
      return "ROLE_INSURANCE_OPERATOR";
    } else {
      return "ROLE_OPERATOR";
    }
  };
  return api.employees
    .createOperator({
      employee: {
        email: operator.email,
        phone: operator.phone,
        firstName: operator.firstName,
        lastName: operator.lastName,
        patronymic: operator.patronymic,
        birthDate: moment(operator.birthDate, "DD/MM/YYYY").format(
          "YYYY-MM-DD"
        ),
        gender: operator.gender,
        insuranceCompany: operator.insuranceCompany,
        plainPassword: operator.email,
        role: role(),
      },
      role: role(),
    })
    .then((res) => dispatch({ type: "CREATE_OPERATOR", res }))
    .catch((err) => ({ ...err }));
};

export const updateOperator = (id, data) => {
  let operator = data;
  return api.employees
    .updateEmployee(id, {
      employee: {
        email: operator.email,
        phone: operator.phone,
        firstName: operator.firstName,
        lastName: operator.lastName,
        patronymic: operator.patronymic,
        birthDate: moment(operator.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
        gender: operator.gender,
        insuranceCompany: operator.insuranceCompany,
        plainPassword: operator.email,
        isAdmin: operator.isAdmin,
      },
    })
    .then((res) => res)
    .catch((err) => err);
};

export const deleteEmployee = (id) => (dispatch) => {
  return api.employees
    .deleteEmployee(id)
    .then((res) => dispatch({ type: "DELETE_EMPLOYEE", res }));
};

export const createDoctor = (data) => (dispatch) => {
  let doctor = data;
  return api.employees
    .createDoctor({
      doctor: {
        educationType: doctor.educationType,
        educationSpecialty: doctor.educationSpecialty,
        experience: doctor.experience,
        specialty: doctor.specialty.map((el) => el.id),
        description: doctor.description,
        employee: {
          email: doctor.email,
          phone: doctor.phone,
          firstName: doctor.firstName,
          lastName: doctor.lastName,
          patronymic: doctor.patronymic,
          birthDate: doctor.birthDate,
          gender: doctor.gender,
          plainPassword: doctor.email,
        },
      },
    })
    .then((res) => dispatch({ type: "CREATE_NEW_DOCTOR", res }))
    .catch((err) => ({ ...err }));
};

export const updateDoctor = (id, data) => (dispatch) => {
  let doctor = data;
  return api.employees
    .updateDoctor(id, {
      doctor: {
        educationType: doctor.educationType,
        educationSpecialty: doctor.educationSpecialty,
        experience: doctor.experience,
        specialty: doctor.specialty.map((el) => el.id),
        description: doctor.description,
        employee: {
          email: doctor.email,
          phone: doctor.phone,
          firstName: doctor.firstName,
          lastName: doctor.lastName,
          patronymic: doctor.patronymic,
          birthDate: moment(doctor.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD"),
          gender: doctor.gender,
          plainPassword: doctor.email,
        },
      },
    })
    .then((res) => dispatch({ type: "UPDATE_DOCTOR", res }));
};

export const deleteDoctor = (id) => (dispatch) => {
  return api.employees
    .deleteDoctor(id)
    .then((res) => dispatch({ type: "DELETE_DOCTOR", res }));
};

export const getDoctorsByFilter = (idFilter = []) => (dispatch) => {
  let filterString = "";
  console.log("idFilter>>", idFilter);
  idFilter.map(
    (el, i) =>
      i > 0
        ? (filterString += `&filter[hospital]=${el}&filter[type]=2`)
        : (filterString += `filter[specialty]=${el}&filter[type]=0`)
    // : (filterString += `filter[specialty]=${el}`)
  );
  return api.employees
    .getAllDoctors("", filterString, 100)
    .then((res) =>
      dispatch({ type: types.GET_DOCTORS_BY_FILTER, data: res && res.data })
    );
};

export const getDoctorsByFilterPrimary = (
  idFilter = [],
  isAdmin = false,
  numberOfPage = ""
) => (dispatch) => {
  let filterString = "";
  if (isAdmin) {
    filterString = idFilter
      .map((el, i) => `filter[specialty][${el}]`)
      .join("&");
  } else {
    filterString = idFilter.map((el, i) => `filter[specialty]=${el}`).join("&");
  }
  // let filterString = idFilter.map((el, i) => `filter[specialty]=${el}`).join('&');

  return api.employees
    .getAllDoctors(numberOfPage, filterString, (numberOfPage && 10) || 100)
    .then((res) =>
      dispatch({ type: types.GET_DOCTORS_BY_FILTER, data: res && res.data })
    );
};

export const getDoctorsByHospitalFilter = ({ specialty, hospital }) => (
  dispatch
) => {
  const filterString = `filter[specialty]=${specialty}&filter[hospital]=${hospital}&filter[type]=2`;

  return api.employees
    .getAllDoctors("", filterString, 100)
    .then((res) =>
      dispatch({ type: types.GET_DOCTORS_BY_FILTER, data: res && res.data })
    );
};

export const getDoctorsByHomeFilter = ({ specialty, district, type }) => (dispatch) => {
  const filterString = `filter[specialty]=${specialty}&filter[type]=${type}${district ? `&filter[district]=${district}` : ''}`;
  console.log(1);
  return api.employees
    .getAllDoctors("", filterString, 100)
    .then((res) =>
      dispatch({ type: types.GET_DOCTORS_BY_FILTER, data: res && res.data })
    );
};

export const setPagination = ({
  filterMode,
  numberOfPage,
  filterTags = [],
}) => (dispatch) => {
  // console.log(filterMode, numberOfPage);
  if (filterMode === "all") {
    return api.employees.getAllEmployees(numberOfPage).then(({ data }) => {
      dispatch({
        type: types.GET_ALL_EMPLOYEES,
        ...data,
        items: data.items,
        filterMode,
      });
    });
  }
  if (filterMode === "doctors") {
    if (filterTags && filterTags.length) {
      const filterString =
        filterTags.map((el, i) => `filter[specialty][${el}]`).join("&") + "&";

      return api.employees
        .getAllDoctors(numberOfPage, filterString, 10)
        .then((res) =>
          dispatch({ type: types.GET_DOCTORS_BY_FILTER, data: res && res.data })
        );
    } else {
      return api.employees.getAllDoctors(numberOfPage, "").then(({ data }) => {
        dispatch({
          type: types.GET_ALL_DOCTORS,
          ...data,
          items: data.items,
          filterMode,
        });
      });
    }
  }
  if (filterMode === "operators") {
    return api.employees.getAllOperators(numberOfPage).then(({ data }) => {
      // console.log(data);
      dispatch({
        type: types.GET_ALL_OPERATORS,
        ...data,
        items: data.items,
        filterMode,
      });
    });
  }
};

export const getDoctorBySearch = (fullName) => (dispatch) => {
  return api.employees.getDoctorBySearch(fullName).then((res) => {
    dispatch({
      type: types.GET_DOCTOR_BY_SEARCH,
      data: { ...(res && res.data) },
    });
  });
};

export const getInsuranceCompanies = () => (dispatch) => {
  return api.employees.getInsuranceCompanies().then((res) => {
    dispatch({
      type: types.GET_INSURANCE_COMPANIES,
      data: { ...(res && res.data) },
    });
  });
};
