import * as types from "./types";
import createReducer from "../../utils/createReducer";
import { parseMessagesList } from "../../utils/common";

const initialHistoryChat = {
  items: [],
  current_page: 0,
  items_per_page: 20,
  page_count: 0,
  total_item_count: 0,
};

const initialState = {
  items: [],
  historyChat: initialHistoryChat,
  countMembersInRoom: 0,
  bothInCall: 0,
  isVideoCall: false,
  isAudioCall: false,
  isRejected: false,
  isNeedScrolling: false,
  connectChatStatus: "disconnected",
};

const chatReducer = createReducer(initialState)({
  [types.GET_CHAT_MESSAGES_TEMPLATES]: (state, { data }) => {
    return {
      ...state,
      templatesItems: data?.items || [],
    };
  },

  [types.SET_CHAT_HISTORY]: (state, { historyChat }) => {
    return {
      ...state,
      historyChat: {
        ...historyChat,
        items: parseMessagesList(historyChat.items, state.historyChat.items),
      },
    };
  },

  [types.SET_NEW_MESSAGE]: (state, { message }) => {
    return {
      ...state,
      historyChat: {
        ...state.historyChat,
        items: parseMessagesList([message], state.historyChat.items),
      },
    };
  },

  [types.SET_TOTAL_CHAT_MEMBERS]: (state, { countMembersInRoom }) => ({
    ...state,
    countMembersInRoom,
  }),

  [types.IS_READ_MESSAGES_LIST]: (state, { idsList }) => {
    const items = state.historyChat.items.map((item) => ({
      ...item,
      list: item.list.map((_) => ({
        ..._,
        status: idsList.includes(_._id) ? 2 : _.status,
      })),
    }));
    return {
      ...state,
      historyChat: {
        ...state.historyChat,
        items,
      },
    };
  },
  [types.CLEAR_HISTORY_CHAT]: (state) => ({
    ...state,
    historyChat: { ...initialHistoryChat },
  }),
  [types.DELETE_CHAT_MESSAGE]: (state, { messageId }) => {
    const newList = state.historyChat.items.map(({ date, list }) => ({
      date,
      list: list.filter((item) => item._id !== messageId),
    }));

    return {
      ...state,
      historyChat: {
        ...state.historyChat,
        items: newList,
      },
    };
  },
  [types.EDIT_CHAT_MESSAGE]: (state, { payload }) => {
    // console.log({text});
    const newList = state.historyChat.items.map((el) => {
      return {
        ...el,
        list: el.list.map((item) => {
          if (item._id === payload.messageId) {
            return {
              ...item,
              message: payload.text,
            };
          }
          return item;
        }),
      };
    });
    console.log("NEW LISTSSSSS", newList);

    return {
      ...state,
      historyChat: {
        ...state.historyChat,
        items: [...newList],
      },
    };
  },
  [types.START_AUDIO_CALL]: (state) => ({ ...state, isAudioCall: true }),
  [types.START_VIDEO_CALL]: (state) => ({ ...state, isVideoCall: true }),
  [types.RESET_CALL]: (state) => ({
    ...state,
    isVideoCall: false,
    isAudioCall: false,
  }),
  [types.END_AUDIO_CALL]: (state) => ({ ...state, isAudioCall: false }),
  [types.END_VIDEO_CALL]: (state) => ({ ...state, isVideoCall: false }),
  [types.SET_REJECTED]: (state, { payload }) => ({
    ...state,
    isRejected: payload,
  }),
  [types.SET_NEED_SCROLLING]: (state, { payload }) => ({
    ...state,
    isNeedScrolling: payload,
  }),
  [types.CHANGE_CONNECT_STATUS_CHAT]: (state, action) => ({
    ...state,
    connectChatStatus: action.payload.status,
  }),
});

export default chatReducer;
