import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import user from "./auth";
import banners from "./banners";
import chat from "./chat";
import companies from "./сompanies";
import consultations from "./consultations";
import employees from "./employees";
import formInitialValues from "./formInitialValues";
import language from "./lang";
import networkError from "./networkError";
import notifications from "./notifications";
import onboarding from "./onboarding";
import patients from "./patients";
import promocodes from "./promocodes";
import searchByFilter from "./searchByFilter";
import shedule from "./shedule";
import specialization from "./specializations";
import subscription from "./subscriptions";
import symptoms from "./symptoms";
// import authReducer from "./auth/reduser";
import hospitals from "./hospitals";
import specializationsMapping from "./specializationMapping";
import popups from './popups'

export default combineReducers({
  language,
  user,
  form: formReducer,
  networkError,
  banners,
  onboarding,
  symptoms,
  monitoring: consultations,
  specialization,
  employees,
  shedule,
  formInitialValues,
  patients,
  searchByFilter,
  subscription,
  chat,
  notifications,
  promocodes,
  companies,
  hospitals,
  specializationsMapping,
  popups,
});
