import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import defaultUserIcon from "../../../style/icons/Subscription/patient_active.svg";
import "./PatientsSearchList.scss";
import { resetPatientsList } from "../../../redux/patients/actions";

const PatientsSearchList = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const goToConsultation = (item) => {
    if (props.role === "doctor") {
      history.push(`/home/doctor/monitoring?page=1&period=All&patientId=${item.patientId}&query=${item.fullName}`);
      return;
    }

    history.push(
      `/home/${props.role}/client/${item.patientId || item.id}/${
        item.patientId ? `${item.id}/` : ""
      }?user=${item.userId}&consultId=`
    );
  };

  const selectPatientHandler = (item) => {
    if (item.type !== 2) {
      goToConsultation(item)
      if (props.role === 'operator') props.resetPatientsList()
      return
    }
  };


  if (props.searchKey && Array.isArray(props.list) && props.list.length) {
    return (
      <div className="patients-search-list with-scrollbar">
        {props.list.map((item, index) => {
          return (
            <div
              onClick={() => selectPatientHandler(item)}
              key={index}
              className="patients-search-item"
            >
              <img
                src={item.photoUrl ? item.photoUrl : defaultUserIcon}
                alt=""
                className="photo"
              />
              <span className="name">{item.fullName}</span>
              {item.phone && (
                <>
                  <span className="phone gray">{t("phone")}</span>
                  <span className="phone">{item.phone}</span>
                </>
              )}
              {/* {typeof item.type === "undefined" && ( */}
              <span className="type">{t("placeholderMedCard")}</span>
              {/* )} */}
            </div>
          );
        })}
      </div>
    );
  }

  if (props.searchKey && Array.isArray(props.list)) {
    return <div className="patients-search-list">{Array.isArray(props)}</div>;
  }

  return <></>;
};

const mapStateToProps = (state) => ({
  list: state.patients && state.patients.searchList,
  searchKey: state.patients && state.patients.searchKey,
});

const mapDispatchToProps = {
  resetPatientsList
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientsSearchList);
