import React from "react";

import "./header.scss";

import Search from "../Search/Search";
import UserTool from "../UserTool/UserTool";
import DoctorsListBySearch from "../DoctorsListBySearch/DoctorsListBySearch";
import { withRouter } from "react-router-dom";
import Button from "../ui/Button/Button";
import dot from "../../../style/icons/DotInCircle/dot-in-circle.svg";
import PatientsSearchList from "../PatientsSearchList/PatientsSearchList";

const Header = ({
  match: {
    params: { role },
  },
}) => {
  return (
    <>
      <div
        className={`headerContainer ${role === "operator" ? "operator" : ""}`}
      >
        <Search role={role} />

        <UserTool />
      </div>
      <DoctorsListBySearch role={role} />
      {/* <PatientsSearchList role={role} /> */}
    </>
  );
};

export default withRouter(Header);
