import * as types from "./types";
import createReducer from "../../utils/createReducer";

const initialState = {
  openModal: false,
  list: [],
  total: 0,
  currentPage: 1,
  loading: false,
  pageCount: 1,
};

const notificationsReducer = createReducer(initialState)({
  [types.TOGGLE_MODAL]: (state, { openModal }) => {
    return {
      ...state,
      openModal,
    };
  },
  [types.GET_NOTIFICATIONS_LIST]: (state, { list, total, currentPage, pageCount, fromButtons }) => {
    if (currentPage > 1 && !fromButtons) {
      return {
        ...state,
        list: state.list.concat(list),
        total,
        currentPage,
        pageCount,
        loading: false,
      }
    }
    return {
      ...state,
      list,
      total,
      currentPage,
      pageCount,
      loading: false,
    };
  },
  [types.ADD_ITEM_TO_LIST]: (state, { item }) => {
    return {
      ...state,
      list: state.list.concat(item),
    };
  },
  [types.GET_REQUEST]: (state, _) => {
    return {
      ...state,
      loading: true,
    };
  },
});

export default notificationsReducer;
