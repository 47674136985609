export const GET_ALL_CONSULTATIONS = "GET_ALL_CONSULTATIONS";
export const GET_FILTERED_CONSULTATIONS = "GET_FILTERED_CONSULTATIONS";
export const GET_TODAY_CONSULTATIONS = "GET_TODAY_CONSULTATIONS";
export const GET_TOMORROW_CONSULTATIONS = "GET_TODAY_CONSULTATIONS";
export const GET_WEEK_CONSULTATIONS = "GET_TODAY_CONSULTATIONS";
export const SET_FILTER_MODE_MONITORING = "SET_FILTER_MODE_MONITORING";
export const CREATE_CONSULTATION = "CREATE_CONSULTATION";
export const UPDATE_CONSULTATION = "UPDATE_CONSULTATION";
export const GET_CURRENT_CONSULTATION = "GET_CURRENT_CONSULTATION";
export const CANCEL_CONSULTATION = "CANCEL_CONSULTATION";
export const RESET_CURR_CONSULTATION = "RESET_CURR_CONSULTATION";
export const GET_RANDOM_DOCTOR_IN_HOSPITAL = "GET_RANDOM_DOCTOR_IN_HOSPITAL";
export const RESET_IS_ITEMS_FILTERED = "RESET_IS_ITEMS_FILTERED";
export const UPDATE_CONSULTATION_STATUS = "UPDATE_CONSULTATION_STATUS"
export const CONSULTATIONS_REQUEST = "CONSULTATIONS_REQUEST"
export const GET_RANDOM_DOCTOR = "GET_RANDOM_DOCTOR"
export const RESET_RANDOM_DOCTOR = "RESET_RANDOM_DOCTOR"
export const CURRENT_CONSULT_REQUEST = "CURRENT_CONSULT_REQUEST"