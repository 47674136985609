import createReducer from "../../utils/createReducer";
import * as types from "./types";

const initialState = {};

const companiesReducer = createReducer(initialState)({
  [types.GET_ALL_COMPANIES]: (state, { type, ...data }) => ({
    ...state,
    ...data,
  }),
});

export default companiesReducer;
