import React, { useEffect } from "react";
import { Form, Field, reduxForm } from "redux-form";
import { compose } from "redux";
import { connect } from "react-redux";
import { getDoctorBySearch } from "../../../redux/employees/actions";
import { searchPatients } from "../../../redux/patients/actions";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";

import SearchInput from "../formComponent/SearchInput/SearchInput";

import icon from "../../../style/icons/Search/search-icon.svg";

import "./search.scss";
import { useCallback } from "react";

const Search = ({ getDoctorBySearch, searchPatients, reset, role }) => {
  const { t } = useTranslation();

  const onChange = (e) => {
    // search function
    localStorage.setItem(
      "numericFromSearch",
      e.target.value.replace(/[^+\d]/g, "")
    );
    if (role === "admin") {
      getDoctorBySearch(e.target.value);
    } else if (
      role === "operator" ||
      role === "doctor" ||
      role === "insurance-operator"
    ) {
      searchPatients(role, e.target.value);
    }
  };

  const debouncedChangeHandler = useCallback(_.debounce(onChange, 600), []);

  const onFocusedOut = (e) => {
    e.preventDefault();
    setTimeout(resetValues, 300);
  };

  const resetValues = () => {
    reset();
  };

  return (
    <div className="wrappSearchForm">
      <Form
        autoComplete="off"
        className="searchForm"
        onSubmit={() => undefined}
      >
        <Field
          name="search"
          icon={icon}
          component={SearchInput}
          onChange={debouncedChangeHandler}
          onBlur={onFocusedOut}
          placeholder={t("placeholderSearch")}
          noLabel
        />
      </Form>
    </div>
  );
};

const enhance = compose(
  connect(null, { getDoctorBySearch, searchPatients }),
  reduxForm({ form: "search" })
);

export default enhance(Search);
