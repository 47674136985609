import React, { useEffect, useState } from "react";
import { Field } from "redux-form";
import { SimpleTextarea } from "../formComponent";
import TextArea from "../formComponent/TextArea/TextArea";

const Anamnez = ({
  formValues = {},
  anamnezFields = [],
  setInitalValues,
  status,
}) => {
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (anamnezFields && anamnezFields !== []) {
      anamnezFields.map((el, i) => {
        formData[el.id] =
          (anamnezFields[i].anamnesis && anamnezFields[i].anamnesis.content) ||
          "";
        setFormData({
          ...formData,
          [el.id]:
            (anamnezFields[i].anamnesis &&
              anamnezFields[i].anamnesis.content) ||
            "",
        });
      });
    }
  }, [anamnezFields]);

  useEffect(() => {
    if (formData && formData !== {}) {
      setInitalValues({ anamnezField: formData, ...formValues });
    }
  }, [formData]);

  return (
    <>
      {!anamnezFields.length ? (
        <p className="emptyList">Список пуст</p>
      ) : (
        anamnezFields &&
        anamnezFields.map((item, i) => (
          <SimpleTextarea
            key={item.id}
            name={item.id}
            // component={Input}
            // component={TextArea}
            placeholder={item.title}
            label={
              formValues[`anamnezField.${i}`] === "" ||
              formValues[`anamnezField.${i}`] === undefined
                ? ""
                : item.title
            }
            defaultValue={
              (item && item.anamnesis && item.anamnesis.content) || ""
            }
            disabled={status === "cancelled" || status === "closed"}
          />
        ))
      )}
    </>
  );
};

export default Anamnez;
