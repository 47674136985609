import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
import PromocodeFilter from "../../components/Filter/PromocodeFilter";
import PromocodesList from "../../components/PromocodesList/PromocodesList";

import {
  getAllPromoCodes,
  setPagination,
  deletePromocode,
  getPromocodesWithParams,
  downloadPromocodesCSV,
} from "../../../redux/promocodes/actions";

import "./PromoCodes.scss";
import { getAllSpecializations } from "../../../redux/specializations/actions";
import { getServerTimeFormat } from "../../../helpers/dateFormats";
import { getSpecialtiesSelector } from "../../../redux/specializationMapping/selectors";
import { compose } from "redux";
import useUpdateEffect from "../../../hooks/useUpdateEffect";
import { withRouter } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";
import { useStyles } from "./styles";
import {createHistoryPush} from "../../../helpers/functions"
import { getInsuranceCompanies } from "../../../redux/employees/actions";
import { getInsuranceCompaniesSelector } from "../../../redux/employees/selectors";

const PromoCodes = ({
  items = [],
  page_count,
  itemsCount,
  deletePromocode,
  role,
  getAllSpecializations,
  specializations,
  history,
  getInsuranceCompanies,
  insurances,
  getPromocodesWithParams,
  downloadPromocodesCSV
}) => {
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [copySuccessCode, setCopySuccessCode] = useState(null)
  const timeoutId = React.useRef(null)
  const { t } = useTranslation();
  const classes = useStyles()
  
  const urlQueries = {
    page: new URLSearchParams(history.location.search).get("page"),
    activatedFrom: new URLSearchParams(history.location.search).get("activatedFrom"),
    activatedTo: new URLSearchParams(history.location.search).get("activatedTo"),
    insuranceCompany: new URLSearchParams(history.location.search).get("insuranceCompany"),
    type: new URLSearchParams(history.location.search).get("type"),
    status: new URLSearchParams(history.location.search).get("status"),
  }
  const {page, activatedFrom, activatedTo, insuranceCompany, type, status} = urlQueries

  useEffect(() => {
    getAllSpecializations(50);
    getInsuranceCompanies()
  }, []);

  const refreshPromocodes = () => {
    setLoading(true)
    getPromocodesWithParams(urlQueries, urlQueries.page)
      .finally(() => setLoading(false))
  }

  useEffect(refreshPromocodes, [])

  useUpdateEffect(refreshPromocodes, [
    insuranceCompany,
    activatedFrom,
    activatedTo,
    status,
    page,
    type,
  ])

  useEffect(() => {
    if (copySuccessCode) {
      timeoutId.current = setTimeout(() => {
        setCopySuccessCode(null)
      }, 400)
    }
    // return () => clearTimeout(timeoutId.current) 
  }, [copySuccessCode])

  const handleDelete = (id) => {
    const confirmation = window.confirm("Вы действительно хотите удалить промокод?")
    if (confirmation) {
      deletePromocode(id)
    }
  }

  const handleChangePage = (_, newPage) => {
    createHistoryPush({
      history,
      ...urlQueries,
      page: newPage
    })
  };

  const downloadCSVHandler = () => {
    setDownloading(true);
    const {activatedFrom, activatedTo, type, status, insuranceCompany} = urlQueries
    const data = {
      status: status || "",
      type: type || "",
      dateStart: activatedFrom || "",
      dateEnd: activatedTo || "",
      companyId: insuranceCompany || "none",
    };
    downloadPromocodesCSV(data)
      .finally(() => setDownloading(false))
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(
        () => setCopySuccessCode(text),
        (error) => console.error('Async: Could not copy text: ', error)
      );
  }

  return (
    <div className="promocodesContainer flex justifyBeetwen">
      <div className="promocodesListContainer">
        <div className="title flex justifyBeetwen">
          <p className="pageTitle">{t("promoCodes")}</p>
          <div className="promocodesCountContainer">
            <Button
              className={"titleItem"}
              text={t("downloadCSV")}
              onClick={downloadCSVHandler}
            />
            <div className="titleItem">
              {t("promocodesCount")} {itemsCount}
            </div>
          </div>
        </div>
        {loading && (
          <div className="absoluteCenter">
            <Spinner />
          </div>
        )}
        {!loading && (
          <PromocodesList
            copyToClipboard={copyToClipboard}
            copySuccessCode={copySuccessCode}
            handleDelete={handleDelete}
            items={items}
            role={role}
            t={t}
          />
        )}
        <div className={classes.root}>
          <Pagination
            classes={{ ul: classes.ul }}
            count={page_count}
            color="primary"
            page={+urlQueries.page}
            defaultPage={1}
            size="large"
            onChange={handleChangePage}
          />
        </div>
      </div>
      <PromocodeFilter
        buttonLoading={loading}
        urlParams={urlQueries}
        insuranceOptions={insurances}
        specialtyOptions={specializations}
      />
    </div>
  )
};

const AddPromoCodes = ({
  create,
  setCreating,
  inputValue,
  setInputValue,
  newPromoCode,
  t,
}) => {
  return (
    <div className={`promoCodesAddictedFormWrapper ${create ? "showing" : ""}`}>
      <div className="formContent">
        <label className="promoCodesCounterLabel" htmlFor="promoCodesCounter">
          {t("promocodeCount")}:
        </label>
        <input
          type="number"
          min={0}
          max={100}
          name="promoCodesCounter"
          value={inputValue}
          title={t("placeholderNumber")}
          placeholder={t("placeholderNumber")}
          onChange={(e) => setInputValue(e.target.value)}
        />
        <Button
          type="button"
          className="btn btn-add alignCenter flex noBackground"
          text={t("addButton")}
          onClick={() => {
            newPromoCode();
            setCreating(false);
          }}
        />
        <Button
          type="button"
          className="btn btn-cncl alignCenter flex noBackground"
          text={t("closeButton")}
          onClick={() => {
            setCreating(false);
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  page_count: state.promocodes && state.promocodes.page_count,
  current_page: state.promocodes && state.promocodes.current_page,
  items: state.promocodes && state.promocodes.items,
  itemsCount: state.promocodes && state.promocodes.total_item_count,
  specializations: getSpecialtiesSelector(state),
  insurances: getInsuranceCompaniesSelector(state),
});

const mapDispatchToProps = {
  setPagination,
  getAllPromoCodes,
  deletePromocode,
  getAllSpecializations,
  getInsuranceCompanies,
  getPromocodesWithParams,
  downloadPromocodesCSV,
}

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps));

export default enhance(PromoCodes);