import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  getAllSpecializations,
  setPagination,
  getSpecializationsByFilter,
} from "../../../redux/specializations/actions";

import uah from "../../../style/icons/Uah/uah.svg";
import specEdit from "../../../style/icons/PersonEdit/person-edit.svg";
import specIcon from "../../../style/icons/SpecialtyIcon/spec-icon.svg";

import {
  setInitalValues,
  resetInitialValues,
} from "../../../redux/formInitialValues/actions";

import TagsInput from "../TagsInput/TagsInput";
import Paginator from "../Paginator/Paginator";
import Spinner from "../ui/Spinner/Spinner";

import "./specializationList.scss";
import { useTranslation } from "react-i18next";

const SpecializationList = ({
  getAllSpecializations,
  setEdit,
  setInitalValues,
  specialization,
  items = [],
  current_page,
  setPagination,
  page_count,
  edit,
  formInitialValues,
  getSpecializationsByFilter,
  token,
  setBlob,
  setAddingImg,
  setFilters,
  filters,
}) => {
  const { t } = useTranslation();
  const [page, setPage] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [loading, setLoading] = useState();
  // console.log({ filters });
  useEffect(() => {
    setLoading(true);
    if (!items) {
      getAllSpecializations(50, current_page && current_page).then((res) => {
        setFilters(res.items);
        getAllSpecializations(10).then(() => setLoading(false));
      });
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   getAllSpecializations(10, current_page && current_page).then((res) =>
  //     setFilters(res.items)
  //   );
  //   // eslint-disable-next-line
  // }, [token]);

  useEffect(() => {
    setLoading(true);
    if (filterTags && filterTags.length) {
      getSpecializationsByFilter(filterTags).then(() => setLoading(false));
    } else {
      getAllSpecializations(50, current_page && current_page).then((res) => {
        setFilters(res.items);
        getAllSpecializations(10).then(() => setLoading(false));
      });
    }
    // eslint-disable-next-line
  }, [filterTags.length]);

  useEffect(() => {
    setPage(new Array(page_count).fill(0));
  }, [page_count]);

  return (
    <>
      <div className="specializationListContainer">
        <div className="titleWithFilters">
          <div className="title">{t("placeholderSpecialty")}</div>
          <TagsInput
            disabled={items && items.length < 1}
            options={filters}
            onChange={(state) => setFilterTags([...state])}
            placeholder={t("placeholderSpecialty")}
          />
        </div>
        <div
          className={`specializationItemsContainer ${
            items && items.length > 0 ? "" : "empty"
          }`}
        >
          {loading && (
            <div className="absoluteCenter">
              <Spinner />
            </div>
          )}

          {!loading && specialization && items && items.length > 0 ? (
            items.map((item) => (
              <div
                key={item.id}
                className={`specializationItem ${
                  edit && item.id === formInitialValues?.id
                    ? "editItem"
                    : edit
                    ? "editMode"
                    : ""
                }`}
              >
                <div className="id">
                  <div className="title">ID</div>
                  <div className="idInfo">#{item.id}</div>
                </div>
                {/*<NavLink to={item ? "/home/admin/doctor" : false}>*/}
                <div className="specialization">
                  <div className="title">{t("placeholderSpecialty")}</div>
                  <div className="specializationInfo">
                    <img src={item.imageUrl || specIcon} alt="" />
                    <span className="name">{item.title}</span>
                  </div>
                </div>

                <div className="cost">
                  <div className="title">{t("cost")}</div>
                  <div className="costInfo">
                    <div className="onLine">
                      <div className="type">{t("priceType")}</div>
                      <div className="value">
                        {item.detail.price}
                        <img src={uah} alt="uah-icon" />
                      </div>
                    </div>
                    <div className="home">
                      <div className="type">{t("callOnHome")}</div>
                      <div className="value">
                        {item.detail.priceHome} <img src={uah} alt="uah-icon" />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="actions">
                  <img
                    src={specEdit}
                    alt="edit-icon"
                    onClick={() => {
                      setEdit();
                      resetInitialValues();
                      setBlob(null);
                      setAddingImg(false);
                      console.log(item);
                      setInitalValues({
                        ...item,
                        priceOnline: item.detail.price.toString(),
                        priceHome: item.detail.priceHome,
                        photo: { cropImg: item.imageUrl },
                        formatType: {
                          value: 'online'
                        },
                      });
                    }}
                  />
                </div>
              </div>
            ))
          ) : (
            <>{!loading && <p>{t("emptyList")}</p>}</>
          )}
        </div>
        {page && page.length >= 1 && page_count !== 0 && (
          <Paginator
            current_page={current_page}
            setPagination={setPagination}
            setLoading={setLoading}
            page_count={page_count}
            intervalDisabled
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = ({ specialization, formInitialValues, user }) => ({
  specialization,
  token: user && user.token,
  items: specialization && specialization.items,
  current_page: specialization && specialization.current_page,
  page_count: specialization && specialization.page_count,
  formInitialValues,
});

export default connect(mapStateToProps, {
  getAllSpecializations,
  setInitalValues,
  setPagination,
  getSpecializationsByFilter,
})(SpecializationList);
