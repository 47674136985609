import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { getAllFavoritePatients, setPagination } from "../../../redux/patients/actions";

import FavoritePatientsList from "../../components/FavoritePatientsList/FavoritePatientsList";
import Splitter from "../../components/ui/Splitter/Splitter";

import "./FavoritePatients.scss";

const FavoritePatients = ({
  listItems = [],
  getAllFavoritePatients,
  role = "",
  current_page = Number,
  page_count = Number,
  setPagination,
}) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setLoading(true);
    getAllFavoritePatients();
    setLoading(false);
  }, []);

  return (
    <Splitter
      left={
        <FavoritePatientsList
          listItems={listItems}
          loading={loading}
          setLoading={setLoading}
          role={role}
          current_page={current_page}
          page_count={page_count}
          setPagination={setPagination}
          t={t}
        />
      }
      right={null}
    />
  );
};

const mapStateToProps = ({ patients }) => ({
  listItems:
    patients && patients.favoritePatients && patients.favoritePatients.items,
  current_page:
    patients &&
    patients.favoritePatients &&
    patients.favoritePatients.current_page,
  page_count:
    patients &&
    patients.favoritePatients &&
    patients.favoritePatients.page_count,
});

export default connect(mapStateToProps, { getAllFavoritePatients, setPagination })(
  FavoritePatients
);
