import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { AttachFileComponent, TemplatesModalContent } from "./";
import Button from "../../ui/Button/Button";
import ModalWindow from "../../ModalWindow/ModalWindow";
import Spinner from "../../ui/Spinner/Spinner";
import {
  startConsultation,
  sendChatMessage,
  editChatMessage,
  chatOperatorAudioMsgUpload,
  chatDoctorAudioMsgUpload,
} from "../../../../redux/chat/actions";
import api from "../../../../api";
import { dataURLtoFile } from "../audioMessage";
import { startRecording, stopRecording } from "../audioSS";
import { setNetworkError } from "../../../../redux/networkError/actions";
import { getCurrentConsultation } from "../../../../redux/consultations/actions";
import {
  getBothInChat,
  getTemplatesItems,
  getChatStatus,
} from "../../../../redux/chat/selectors";

import muted_audio from "../../../../style/icons/Chat/muted_audio.svg";
import deleteButton from "../../../../style/icons/audioMessage/delete.svg";
import sendButton from "../../../../style/icons/audioMessage/send-button.svg";
import waveform from "../../../../style/icons/Chat/waveform.svg";
import playButton from "../../../../style/icons/Chat/playbtn.svg";
import pauseButton from "../../../../style/icons/Chat/pausebtn.svg";
import open_menu from "../../../../style/icons/Chat/open-menu.svg";
import microphone from "../../../../style/icons/Chat/microphone.svg";
import useUpdateEffect from "../../../../hooks/useUpdateEffect";

let audioBlob = null;
const MAX_ROWS = 10;
const MIN_ROWS = 1;
const TEXTAREA_LINE_HEIGHT = 16;

const ChatTools = ({
  startConsultation,
  status,
  messageValue,
  setMessageValue,
  setMessageIdEdit,
  t,
  consultId,
  videoCall,
  setNetworkError,
  isAttendingDoctor,
  patientID,
  templatesItems,
  role,
  doctor,
  messageIdEdit,
  sendChatMessage,
  editChatMessage,
  chatOperatorAudioMsgUpload,
  chatDoctorAudioMsgUpload,
  bothInChat,
  getCurrentConsultation,
  currentConsultation,
  chatStatus,
}) => {
  const [isOpenTemplatesModal, toggleTemplatesModal] = useState(false);
  const [audioPlay, setAudioPlay] = useState(null);
  const [startConsultLoading, setStartConsultLoading] = useState(false);
  const [audioTime, setAudioTime] = useState(null);
  const [startedRecording, setStartRecording] = useState(false);
  const [audioDuration, setAudioDuration] = useState(null);
  const [isAudioPlay, setIsAudioPlay] = useState(false);
  const [textarea_rows, setTextareaRows] = useState(1);
  const [recorded, setRecorded] = useState(false);
  const [canceled, setCanceled] = useState(null);
  const prevAudioContainerRef = useRef(null);
  const [internetCoonect, setIsInternetConnect] = useState(navigator.onLine);
  const prevAudioRef = useRef(null);
  const audioUrlRef = useRef(null);
  const interId = useRef(null);

  const handleAudioEnded = () => {
    setIsAudioPlay(false);
    prevAudioContainerRef.current.classList.remove("animation");
  };
  const handleAudioPause = () => setAudioPlay(false);
  const handleAudioError = () => setAudioPlay(false);
  const handleClickPrevPlayAudio = () => {
    setIsAudioPlay((prevState) => !prevState);

    prevAudioContainerRef.current.classList.add("animation");
    if (isAudioPlay) {
      // setIsAudioPlay(false)
      prevAudioRef.current.pause();
      prevAudioContainerRef.current.style.animationPlayState = "paused";
    } else {
      // setAudioPlay(true)
      prevAudioContainerRef.current.style.animationPlayState = "running";
      prevAudioRef.current.play();
    }
  };

  const startRecordingAudioMessage = async () => {
    if (startedRecording) {
      setAudioDuration(audioTime);
      setStartRecording(false);
      stopRecording().then(({ blob, audioUrl }) => {
        console.log({
          blob,
          audioUrl,
        });
        audioBlob = blob;
        audioUrlRef.current = audioUrl;
        // prevAudioRef.current.src = audioUrl;
        setRecorded(true);
      });
    } else {
      startRecording();
      setStartRecording(true);
      setCanceled(false);
    }
  };

  const deleteAudioMessage = () => setRecorded(false);

  const keyActions = (event) => {
    if (messageIdEdit && messageValue.trim() && event.keyCode === 13) {
      event.preventDefault();
      editChatMessage({ messageId: messageIdEdit, text: messageValue, role });
      setMessageIdEdit(null);
      setTextareaRows(1);
      sendNotification();
      setMessageValue("");
      return;
    }
    if (event.keyCode === 13 && event.ctrlKey) {
      const val = messageValue + "\r\n";
      setMessageValue(val);
    }

    if (messageValue && messageValue.trim() && event.keyCode === 13) {
      event.preventDefault();
      sendChatMessage({
        type: "message",
        userId: patientID,
        text: messageValue,
        some: "param",
      });
      setMessageValue("");
      setTextareaRows(1);
      sendNotification();
    }
  };

  const sendNotification = () => {
    console.log(bothInChat, role);
    if (bothInChat > 1) {
      return;
    }
    if (role === "operator") {
      api.notification.sendPushNotificationFromOperatorRole(patientID);
    }

    if (role === "doctor") {
      api.notification.sendPushNotification({
        appointmentId: consultId,
        type: 0, // VIDEO = 2
      });
    }
  };

  useEffect(() => {
    const handleOffline = () => {
      setIsInternetConnect(false);
    };
    const handleOnline = () => {
      setIsInternetConnect(true);
    };
    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const handleTemplateMessage = (text) => {
    sendChatMessage({
      type: "message",
      text,
      appointmentId: consultId,
      userId: patientID,
      some: "sam some",
    });

    toggleTemplatesModal(false);

    sendNotification();
  };

  const cancelRecording = () => {
    setCanceled(true);
    startRecordingAudioMessage();
  };

  const handleMessageChange = (e) => {
    const previousRows = e.target.rows;
    e.target.rows = MIN_ROWS; // reset number of rows in textarea
    setMessageValue(e.target.value);
    const currentRows = ~~(e.target.scrollHeight / TEXTAREA_LINE_HEIGHT);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }
    if (currentRows >= MAX_ROWS) {
      e.target.rows = MAX_ROWS;
      e.target.scrollTop = e.target.scrollHeight;
    }
    setTextareaRows(currentRows < MAX_ROWS ? currentRows : MAX_ROWS);
  };

  const handleClickStartConsultation = () => {
    setStartConsultLoading(true);
    startConsultation(consultId)
      .then((response) => {
        if (response.message) {
          setNetworkError({
            response: {
              succes: false,
              status: response.code,
              warning: t("warning"),
              data: {
                message: response.message,
              },
            },
          });
        }
        return response;
      })
      .then(
        (response) =>
          response && response.entity && getCurrentConsultation(consultId)
      )
      .finally(() => setStartConsultLoading(false));
  };

  useUpdateEffect(() => {
    const startTime = new Date();
    interId.current = setInterval(() => {
      const currentTime = new Date();
      const deltaTime = currentTime - startTime;
      setAudioTime(deltaTime);
    }, 1000);

    return () => {
      clearInterval(interId.current);
      setAudioTime(null);
    };
  }, [startedRecording, canceled]);

  const sendAudioMessage = (e) => {
    e.preventDefault();
    setRecorded(false);
    var reader = new FileReader();
    reader.readAsDataURL(audioBlob);

    reader.onloadend = function () {
      const base64data = reader.result;
      const audioFile = dataURLtoFile(base64data, "audio.wav");
      audioBlob = null;
      if (role === "operator") {
        chatOperatorAudioMsgUpload({
          userId: patientID,
          audiofile: audioFile,
          audioDuration: audioDuration,
        });
        return;
      }
      chatDoctorAudioMsgUpload({
        id: consultId,
        audioFile: base64data,
        audioDuration: audioDuration,
      });
    };
  };

  const isGotCurrentConsult =
    (currentConsultation && currentConsultation.id.toString() === consultId) ||
    role === "operator";
  const isChatDisconnected = !internetCoonect || chatStatus === "disconnect";
  const isChatConncting = internetCoonect && chatStatus === "connecting";
  const isChatConnected = internetCoonect && chatStatus === "connected";
  return (
    <>
      {isOpenTemplatesModal && (
        <ModalWindow
          isOpen={isOpenTemplatesModal}
          toggleOpen={() => toggleTemplatesModal(false)}
        >
          <TemplatesModalContent
            t={t}
            templates={templatesItems}
            handleClose={() => toggleTemplatesModal(false)}
            handleMessageClick={handleTemplateMessage}
          />
        </ModalWindow>
      )}
      <div
        className={`chatFooter ${
          status === "cancelled" || status === "closed" ? "hidden" : ""
        }`}
      >
        {isChatDisconnected && (
          <p className="error-is-not-internet">{t("noConnectToServer")}</p>
        )}
        {isChatConncting && (
          <p className="info-connect-to-server">{t("coonectingChatStatus")}</p>
        )}
        {isChatConnected &&
        (status === "in_process" || !doctor) &&
        isGotCurrentConsult ? (
          <form className="preForm">
            <div
              className={`textarea_wrapp ${isAttendingDoctor ? "wide" : ""} ${
                startedRecording ? "isRecording" : ""
              } ${recorded ? `p5` : ""}`}
            >
              {recorded && (
                <div className="mainPrevContainer">
                  <div className={`prevAudioContainer`}>
                    <div
                      ref={prevAudioContainerRef}
                      className="animation"
                    ></div>
                    <audio
                      // onPlay={() => setAudioPlay(true)}
                      onPause={handleAudioPause}
                      onEnded={handleAudioEnded}
                      onError={handleAudioError}
                      preload="metadata"
                      ref={prevAudioRef}
                      src={audioUrlRef.current}
                      // ref={audioUrlRef}
                    />
                    <div className="prevAudioFile">
                      <img
                        className="prevPlayAudioImg"
                        src={isAudioPlay ? pauseButton : playButton}
                        alt=""
                        onClick={handleClickPrevPlayAudio}
                      />
                      <div className="prevWaveContainer">
                        <img
                          className="prevWavePicture"
                          src={waveform}
                          alt={"etete"}
                        />
                      </div>
                      <div>
                        <span className="prevAudioDuration">
                          {audioDuration
                            ? moment(audioDuration).format("mm:ss")
                            : "00:00"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="audioButtonsContainer">
                    <button
                      className="sendAudioButton"
                      onClick={sendAudioMessage}
                    >
                      <img
                        className="prevActionPicture"
                        src={sendButton}
                        alt={"send-audio"}
                      />
                    </button>
                    <button
                      className="deleteAudioButton"
                      onClick={deleteAudioMessage}
                    >
                      <img
                        className="prevActionPicture"
                        src={deleteButton}
                        alt={"delete-audio"}
                      />
                    </button>
                  </div>
                </div>
              )}
              {startedRecording && !recorded && (
                <div className="cancelContainer">
                  <span className="stop-btn" onClick={cancelRecording}>
                    {t("cancelButton")}
                  </span>
                  <span
                    className={`${
                      startedRecording ? "recording-time" : "dnone"
                    }`}
                  >
                    {audioTime ? moment(audioTime).format("mm:ss") : "00:00"}
                  </span>
                </div>
              )}

              {!startedRecording && !recorded && (
                <textarea
                  className="formFieldDoc textAreaDoc"
                  id="chatField"
                  placeholder={t("placeholderChatMessage")}
                  onChange={handleMessageChange}
                  rows={textarea_rows}
                  value={messageValue || ""}
                  onKeyDown={keyActions}
                />
              )}
            </div>
            <div
              className={`icon-buttons ${
                startedRecording ? "isRecording" : ""
              }`}
            >
              <div className="micro-container">
                <img
                  className={`icon-button ${
                    startedRecording ? "recording" : ""
                  }`}
                  src={startedRecording ? muted_audio : microphone}
                  alt=""
                  onClick={startRecordingAudioMessage}
                />
              </div>
              <div
                className="icon-button"
                onClick={() => toggleTemplatesModal(true)}
              >
                <img src={open_menu} alt="" />
              </div>
              <AttachFileComponent
                t={t}
                role={role}
                consultationId={consultId}
                userId={patientID}
                sendChatMessage={sendChatMessage}
              />
            </div>
          </form>
        ) : (
          <>
            {!status && (
              <div className="loaderMedium">
                <Spinner small />
              </div>
            )}
            {isChatConnected &&
              status &&
              status !== "closed" &&
              isGotCurrentConsult && (
                <Button
                  onClick={handleClickStartConsultation}
                  className={
                    videoCall ? "btn startConsult video" : "btn startConsult"
                  }
                  text={t("placeholderStartConsultation")}
                  disabled={
                    status === "cancelled" ||
                    status === "closed" ||
                    status === "in_process"
                  }
                  // customDisabled={startConsultLoading}
                  loading={startConsultLoading}
                />
              )}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  templatesItems: getTemplatesItems(state),
  bothInChat: getBothInChat(state),
  doctor: state.user.doctor,
  chatStatus: getChatStatus(state),
});

export default connect(mapStateToProps, {
  setNetworkError,
  sendChatMessage,
  startConsultation,
  chatOperatorAudioMsgUpload,
  chatDoctorAudioMsgUpload,
  getCurrentConsultation,
  editChatMessage,
})(ChatTools);
