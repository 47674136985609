import React, { useState } from "react";
import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";
import api from "../../../../api";
import { bytesToSize } from "../../../../helpers/functions";
import attach_file from "../../../../style/icons/Chat/attach-file.svg";
import useOnClickOutside from "../../../../hooks/useOutsideClick";

export const AttachFileComponent = ({
  role,
  patientID,
  sendChatMessage,
  userId,
  t,
  consultationId,
}) => {
  const [fileAttach, setFileAttach] = useState(false);
  const [statusUploadFile, setStatusUploadFile] = useState(0);
  const attachRef = React.useRef(null)
  const inputImageRef = React.useRef(null)
  const inputFileRef = React.useRef(null)

  useOnClickOutside(attachRef, () => setFileAttach(false))

  const changeHandler = (e) => {
    setFileAttach(false);

    const inputFile = e.target.files[0];
    const isFileType = (fileType = "") => {
      if (fileType.includes("image")) {
        return "image";
      }
      if (fileType.includes("video")) {
        return "video";
      }
      if (
        fileType.includes("pdf") ||
        fileType.includes("application") ||
        fileType.includes("text")
      ) {
        return "file";
      }
    };
    if (role === "doctor") {
      api.chat
        .chatFileUpload({
          id: consultationId,
          file: inputFile,
          setUploadStatus: setStatusUploadFile,
        })
        .then((res) =>
          sendChatMessage({
            type: isFileType(inputFile.type),
            text: res.entity.file,
            appointmentId: consultationId,
            file_name: inputFile.name,
            size: bytesToSize(inputFile.size),
          })
        )
        .finally(() => setStatusUploadFile(0));
    } else {
      api.chat
        .chatOperatorFileUpload({
          id: userId,
          file: inputFile,
          setUploadStatus: setStatusUploadFile,
        })
        .then((res) => {
          sendChatMessage({
            type: isFileType(inputFile.type),
            text: res.entity.file,
            userId,
            file_name: inputFile.name,
            size: bytesToSize(inputFile.size),
          });
        })
        .finally(() => setStatusUploadFile(0));
    }

    e.target.value = "";
  };

  const checkCancel = () => {
    if (inputImageRef.current.value.length) return
    if(inputFileRef.current.value.length) return
    setFileAttach(false)
    document.body.onfocus = () => {};
  }

  const initialize = () => {
    document.body.onfocus = checkCancel;
  }

  return (
    <div
      ref={attachRef}
      className="icon-button document-attach"
      onClick={() => {
        if (statusUploadFile === 0) {
          setFileAttach((prev) => !prev);
        }
      }}
    >
      {statusUploadFile === 0 ? (
        <img src={attach_file} alt="" />
      ) : (
        <CircularProgressbar
          value={statusUploadFile}
          text={`${statusUploadFile}%`}
          counterClockwise
          initialAnimation
        />
      )}
      <div
        className="file-inputs"
        style={{ display: fileAttach ? "block" : "none" }}
      >
        <label className="label" htmlFor="img">
          {t("placeholderImageOrVideo")}
        </label>
        <input
          ref={inputImageRef}
          className="fileinput"
          onChange={changeHandler}
          type="file"
          id="img"
          name="img"
          accept="image/*,video/mp4,video/x-m4v,video/*"
          onClick={initialize}
        />
        <label className="label" htmlFor="doc">
          {t("placeholderDocument")}
        </label>
        <input
          ref={inputFileRef}
          className="fileinput"
          onChange={changeHandler}
          type="file"
          id="doc"
          name="doc"
          onClick={initialize}
          accept="text/plain,application/pdf,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation"
        />
      </div>
    </div>
  );
};
