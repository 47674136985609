import { createSelector } from "reselect";
import { getListsWithDates } from "../../utils/common";
import patientIcon from "../../style/icons/Subscription/patient_active.svg";
import { consultationFormats } from "../../views/components/NewConsultation/formats";
import docIcon from "../../style/icons/DocIcon/doc.svg";
import i18n from "../../i18n"
import noPayment from "../../style/icons/NoPayment/noPayment.svg";
import moment from "moment"

const defaultConsultations = {
  items: [],
  filter_params: "",
  total_item_count: 0,
  current_page: 0,
  filter_params: "",
  trigger_count: "",
  currentConsultation: {},
  loading: false,
  randomDoctor: null,
};

const defaultLoading = false

const defaultEntity = null

const defaultState = { monitoring: {consultations: { ...defaultConsultations }} };

const getConsultations = (state = defaultState) =>
  state.monitoring.consultations || defaultConsultations;

const getLoading = (state = defaultLoading) =>
  state.monitoring.loading || defaultLoading;

const getNewEntity = (state = defaultEntity) =>
  state.monitoring.newConsultEntity || defaultEntity;

export const getConsultationsListsByDate = createSelector(
  [getConsultations],
  (monitoring) => {
    const list = getListsWithDates(monitoring.items);

    return list;
  }
);
export const getFilterItemsCount = createSelector(
  [getConsultations],
  ({ count }) => count
);

// export const getRandomDoctorPeriodsSelector = createSelector(
//   [getConsultations],
//   ({ data }) => data?.entity?.periods
// );
export const getCurrentConsultPatientSelector = createSelector(
  [getConsultations],
  ({ currentConsultation }) => currentConsultation
)

export const getCurrentConsultSelector = createSelector(
  [getConsultations],
  ({ currentConsultation }) => {
    if(!currentConsultation) return {}
    const {patientCard, type, specialty, address, doctor, start, status, id, patient} = currentConsultation
    const resultFormat = {
      id: id,
      patient: {
        ...patient
      },
      card: {
        imgUrl: patientCard?.photoUrl || patientIcon,
        title: patientCard?.fullName,
        id: patientCard?.id,
        specialty: patientCard?.specialty,
      },
      specialization: {
        imgUrl: specialty?.imageUrl || patientIcon,
        title: specialty?.title,
        id: specialty?.id,
      },
      format: consultationFormats.find(el => el.num_type == type),
      province: address?.province || undefined,
      district: address?.district || undefined,
      address: {
        ...address
      },
      doctor: {
        imgUrl: doctor?.employee?.photoUrl || docIcon,
        title: doctor?.employee?.fullName,
        id: doctor?.id,
        specialty: doctor?.specialty,
      },
      reservationDate: {
        day: moment(start).format("YYYY-MM-DD"),
        hours: moment(start).format("HH:mm"),
      },
      status: status,
      paymentMode: { title: i18n.t("noPayment"), imgUrl: noPayment },
    }
    delete resultFormat.address.id;
    delete resultFormat.address.province;
    delete resultFormat.address.district;
    return resultFormat;
  }
);

export const getCurrentConsultCardData = createSelector(
  [getConsultations],
  ({ currentConsultation }) => currentConsultation && currentConsultation.status === "closed" ? ({
    disease: currentConsultation.disease,
    analysis: currentConsultation.analysis,
    drugs: currentConsultation.drugs,
    specializations: currentConsultation.specialtyRecommend,
    diagnostic: currentConsultation.diagnostic,
    complaintsText: currentConsultation.complaintsText,
    currentAnamnesisText: currentConsultation.currentAnamnesisText,
    allergicText: currentConsultation.allergicText,
    pastIllnessText: currentConsultation.pastIllnessText,
    treatmentPlanText: currentConsultation.treatmentPlanText,
    conclusionText: currentConsultation.conclusionText,
  }) : null
)

export const getPageCount = createSelector(
  [getConsultations],
  ({ page_count }) => page_count
);
export const getCurrentPage = createSelector(
  [getConsultations],
  ({ current_page }) => current_page
);
export const getIsItemsFiltered = createSelector(
  [getConsultations],
  ({ isItemsFiltered }) => isItemsFiltered
);
export const getFilterParams = createSelector(
  [getConsultations],
  ({ filter_params }) => filter_params
);
export const getTriggerCount = createSelector(
  [getConsultations],
  ({ trigger_count }) => trigger_count
);
export const getTotalItemCount = createSelector(
  [getConsultations],
  ({ total_item_count }) => total_item_count
);
export const getFilterMode = createSelector(
  [getConsultations],
  ({ filterMode }) => filterMode
);

export const getConsultLoading = createSelector(
  [getConsultations],
  ({ consultLoading }) => consultLoading
);

export const getLoadingState = createSelector(
  [getLoading],
  (loading) => loading
)

export const getNewConsultEntity = createSelector(
  [getNewEntity],
  (newConsultEntity) => newConsultEntity
)
