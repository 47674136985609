import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Form, getFormValues, reduxForm, Field } from "redux-form";
import { compose } from "redux";
import { useTranslation } from "react-i18next";

import patient from "../../../style/icons/Subscription/patient.svg";
// import patient_active from "../../../style/icons/Subscription/patient_active.svg";
import company from "../../../style/icons/Subscription/company.svg";
import company_active from "../../../style/icons/Subscription/company_active.svg";
import calendar from "../../../style/icons/Calendar/calendar.svg";
import close from "../../../style/icons/Close/close-icon.svg";
import trash from "../../../style/icons/Trash/trash.svg";
import arrowLeft from "../../../style/icons/ArrowLeftLight/arrow-left.svg";
import arrowRight from "../../../style/icons/ShevronRight/shevron.svg";
import plus from "../../../style/icons/PlusInCircle/plus.svg";
import docAvatar from "../../../style/icons/DoctorAvatar/avatar.svg";
import moment from "moment"
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import { getAllSpecializations } from "../../../redux/specializations/actions";
import { setSubs } from "../../../redux/subscriptions/actions";
import {
  setNetworkError,
  setPopUpSuccess,
} from "../../../redux/networkError/actions";

import api from "../../../api";
import { voidRequired } from "../../../helpers/validate";

import FileUploader from "../../components/formComponent/PhotoPassUploader/PhotoPassUploader";
import ToggleImg from "../../components/formComponent/ToggleImg/ToggleImg";
import Toggle from "../../components/formComponent/Toggle/Toggle";
import Input from "../../components/formComponent/Input/Input";
import DatePicker from "../../components/formComponent/DatePicker/DatePicker";
import FilterSelect from "../../components/formComponent/FilterSelect/FilterSelect";
import Button from "../../components/ui/Button/Button";
import Spinner from "../../components/ui/Spinner/Spinner";
// import '../NewEmployeeForm/newEmployeeForm.scss'
// import "../NewSubscriptionForm/NewSubscriptionForm.scss";
import './bannerForm.scss'
import TextArea from "../../components/formComponent/TextArea/TextArea";
import subscriptions from "../../../api/subscriptions";

const BannerForm = ({
  formValues,
  initialValues,
  setInitalValues,
  setEditBannerOpen,
  setAddingImg,
  addingImg,
  bannerInfo = {},
  blob,
  setBlob,
  setSubs,
  setNetworkError,
  setPopUpSuccess,
  setLoading,
  loading,
}) => {
  // const [loading, setLoading] = useState(false)
  // TO DO WITH BACK
  const [subscription, setSubscription] = useState(null)
  const [specialtyMap, setSpecialtyMap] = useState(null)
  let lang = localStorage.getItem("i18nextLng").slice(0, 2)
  if (lang === 'uk') lang = 'ua'
  
  useEffect(() => {
    setInitalValues({
      ...formValues,
      photo: subscription?.imageUrl,
      // photo: subscription?.imageUrl,
      description: subscription?.descriptionTranslations[lang],
      shortDescription: subscription?.shortDescription,
      price: subscription?.price,
    });
  }, [subscription])

  useEffect(() => {
    
  }, [formValues])

  useEffect(() => {
    setSubscription({
      ...bannerInfo.subscription,
      imageUrl: bannerInfo.imageUrl,
    })
    setSpecialtyMap(bannerInfo.subscription.specialtyMap.map((el) => {
      return { specialty: el.specialty.id, discount: el.discount }
    }))
  }, [bannerInfo])

  console.log('FORM VALUES', formValues);
  console.log("SPECIALTY MAP", specialtyMap);
  const { t } = useTranslation()

  const editSubcriptionBanner = async (e) => {
    e.preventDefault()
    setLoading(true)
    let data = {
      company: {
        title: bannerInfo.title ?? "Название компании",
        subscription: {
          title: subscription.title ?? "Название компании",
          description: formValues.description,
          price: formValues.price,
          validFrom: subscription.validFrom,
          validTill: subscription.validTill,
          activePeriod: subscription.activePeriod,
          specialtyScope: subscription.specialtyScope,
          discount: subscription.discount,
          shortDescription: formValues.shortDescription,
        },
      },
    };
    if (subscription.specialtyScope !== 0) {
      data = {
        ...data,
        company: {
          ...data.company,
          subscription: {
            ...data.company.subscription,
            specialtyMap: specialtyMap,
          }
        }
      }
    }
    if (blob) {
      try {
        await api.subscriptions.updateCompanyPhoto(bannerInfo.id, blob)
        console.log("Successfully edit company photo");
      } catch {
        setBlob(null);
        console.error("ERROR_MESSAGE");
      }
    }
    return api.subscriptions.editCompany(bannerInfo.id, data)
      .then((res) => {
        if (res.code) {
          setNetworkError({
            response: {
              status: t("warning"),
              statusText: res.message,
            },
          });
        } else {
          setPopUpSuccess({
            response: {
              succes: true,
              status: t("success"),
              data: {
                message: t("subscriptionEdited"),
              },
            },
          });
        }
      }).then(() => {
        setEditBannerOpen(false)
        setLoading(false)
        // setSubs()
      }).then(() => {
        setSubs()
      })
  }
  return (
    <div className='bannerFormContainer'>
      {loading ? (
        <div className="absoluteCenter">
          <Spinner />
        </div>
      ) : (
        <div className='bannerFormContainer'>
          <div className='bannerFormTitleContainer'>
            <div className='bannerFormTitle'>{t("newScheduleFilterFormTitleEdit")}</div>
            <img src={close} onClick={() => setEditBannerOpen(false)} alt="closeIcon" />
          </div>
          <Form className="subscriptionForm" onSubmit={editSubcriptionBanner}>
            {/* {formValues && formValues.photo && formValues.photo.cropImg ? ( */}
            <div className='editingSectionTitle'>{t("photo")}</div>
            {formValues && formValues.photo && formValues.photo ? (
              <div className="specAvatarBanner">
                <div className="cropContainer">
                  <img
                    className="cropBannerPhoto"
                    src={formValues.photo || formValues.photo.cropImg}
                    alt="avatar-icon"
                  />
                  <div className="fileName">
                    {/* {formValues && formValues.photo && formValues.photo.fileName.slice(0, 10)+'...'} */}
                  </div>
                </div>
                <div
                  className="trashIcon"
                  onClick={() => {
                    // reset();
                    setInitalValues({
                      ...formValues,
                      photo: null,
                    });
                  }}
                >
                  <img src={trash} alt="" />
                </div>
              </div>
            ) : (
              <div>
                <Button
                  secondary
                  withIcon
                  icon={plus}
                  // shevron={shevroRight}
                  type="button"
                  className="btn"
                  text={t("addImage")}
                  onClick={() => {
                    setAddingImg(true)
                  }}
                  disabled={formValues.isBlocked}
                />
              </div>
            )}
            {addingImg && (
              <div className={`addingImg ${addingImg ? "show" : ""}`}>
                <div className="formTitle">
                  <img
                    src={arrowLeft}
                    alt="arrow-icon"
                    onClick={() => {
                      setAddingImg(false);
                    }}
                  />
                  {t("addImage")}
                </div>
                <div className="photoUploadFieldContainer">
                  <Field
                    name="photo"
                    // validate={[voidRequired]}
                    component={FileUploader}
                    previewTrashIcon={trash}
                    // uploadReq={() => console.log("action")}
                    close={() => {
                      setSubscription(prev => ({
                        ...prev,
                        imageUrl: formValues.photo.cropImg
                      }))
                      setAddingImg(false)
                    }}
                    editingImgUrl={
                      initialValues &&
                      initialValues.photo &&
                      initialValues.photo.cropImg
                    }
                    setBlob={setBlob}
                    // setEditingOff={setEditingOff}
                    // disabled={formValues.isBlocked}
                    t={t}
                  />
                </div>
              </div>
            )}
            <div style={{ marginTop: "20px" }}>
              <div className='editingSectionTitle'>{t("shortDescription")}</div>
              <Field
                // validate={[voidRequired]}
                name="shortDescription"
                type="number"
                component={TextArea}
                  placeholder={t("shortDescription")}
                  rows={3}
              // disabled={formValues.isBlocked}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              <div className='editingSectionTitle'>{t("description")}</div>
              <Field
                // validate={[voidRequired]}
                name="description"
                type="number"
                component={TextArea}
                placeholder={t("specializationDescription")}
                rows={5}
              // disabled={formValues.isBlocked}
              />
            </div>
            <div>
              <div className='editingSectionTitle'>{t("cost")}</div>
              <Field
                name="price"
                type="number"
                min="0"
                max="50000"
                validate={[voidRequired]}
                component={Input}
                placeholder={t("priceDiscount")}
                withPreview
              // selectedOption={formValues && formValues.discount}
              />
            </div>
            <div>
              <Button
                type="submit"
                className="btn"
                text={t("edit")}
              // disabled={formValues.isBlocked}
              />
            </div>
          </Form>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  getAllSpecializations,
  setSubs,
  setInitalValues,
  resetInitialValues,
  setNetworkError,
  setPopUpSuccess,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("subscription")(state),
  initialValues: state.formInitialValues,
  specializations: state.specialization && state.specialization.items,
  token: state.user.token,
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "subscription", enableReinitialize: true })
);

export default enhance(BannerForm);