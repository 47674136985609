import React, { useEffect, useState } from "react";

import { convertBase64ToFile } from "../../../../helpers/functions";

import trash from "../../../../style/icons/Trash/trash-icon.svg";
import excel from "../../../../style/icons/Excsel/Excel.svg";
import plus from "../../../../style/icons/PlusInCircle/plus-blue.svg";

import { useTranslation } from "react-i18next";

import "./fileUploader.scss";

const FileUploader = ({ input, uploadReq, submit }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    fileList: [],
    file: null,
  });

  useEffect(() => {
    clear();
    return function () {
      clear();
    };
  }, []);

  useEffect(() => {
    if (state.file) {
      clear();
    }
  }, [submit]);

  useEffect(() => {
    submit && submit();
  }, [state.file]);

  const changeHandler = (e) => {
    const file = e && e.target && e.target.files[0];

    e.persist();

    setState({ ...state, fileList: [file] });
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = (event) => {
      const file = convertBase64ToFile(event.target.result);
      input.onChange({ file });
      setState((prev) => {
        return { ...prev, file: file };
      });
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    };
  };

  const clear = () => {
    input.onChange(null);
    setState({
      fileList: [],
      file: null,
    });
  };

  // console.log({ state, v: input.value });
  return (
    <>
      {!state.file && (
        <label className="btn secondary label fileUploader inputFile">
          <span>
            <img src={plus} alt="plus-icon"></img>
            {t("fileUploaderTitle")}
          </span>
          <input
            {...input}
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChange={changeHandler}
            type="file"
            value={(state.file && state.file.name) || ""}
          />
        </label>
      )}
      {state.fileList && state.fileList.length > 0 && (
        <div className="previewBlock flex alignCenter justifyBeetwen">
          <span className="flex alignCenter">
            <img className="previewImage" src={excel} alt="excel-icon" />
            {state.fileList && state.fileList[0]
              ? state.fileList[0].name
              : t("fileUploaderTitle")}
          </span>
          {state.fileList && state.fileList[0] && (
            <img
              style={{ zIndex: "1" }}
              src={trash}
              alt="trash-delete-icon"
              onClick={() => clear()}
            />
          )}
        </div>
      )}
    </>
  );
};

export default FileUploader;
