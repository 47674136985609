import { createSelector } from "reselect";
import patientIcon from "../../style/icons/Subscription/patient_active.svg";
const defaultState = {};

const getPatients = (state = defaultState) => state.patients;

export const getPatientsRelations = createSelector(
  [getPatients],
  ({relations}) => relations || []
);

export const getPatientSelector = createSelector(
  [getPatients],
  ({patient}) => patient
);

export const getPatientsSelectCard = createSelector(
  [getPatients],
  (patients) => patients?.cards?.items ? patients.cards.items.map((card) => ({
    imgUrl: card.photoUrl || patientIcon,
    title: card.fullName,
    id: card.id,
    specialty: card.specialty,
  })) : []
);