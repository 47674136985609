import React, { useState } from "react";

import SubscribersList from "../../components/SubscribersList/SubscribersList";
import NewSubscriberForm from "../../forms/NewSubscriberForm/NewSubscriberForm";

import "./Subscribers.scss";

const Subscribers = ({}) => {
  const [edit, setEdit] = useState(false);

  return (
    <>
      <SubscribersList edit={edit} setEdit={() => setEdit(true)} />
      <NewSubscriberForm />
    </>
  );
};

export default Subscribers;
