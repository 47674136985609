import * as types from "./types";
import api from "../../api";

export const getAllCompanies = () => (dispatch) => {
  return api.companies.getAllCompanies().then((res) => {
    return dispatch({
      type: types.GET_ALL_COMPANIES,
      ...(res && res.data),
    });
  });
};

export const setPagination = ({ numberOfPage }) => (dispatch) => {
  return api.companies.getAllCompanies(numberOfPage).then((res) => {
    return dispatch({
      type: types.GET_ALL_COMPANIES,
      ...(res && res.data),
    });
  });
};

