import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { compose } from "redux";
import { Field, getFormValues, reduxForm } from "redux-form";
import { sendChatMessage } from "../../../redux/chat/actions";

import {
  canselConsultation,
  fillingCard,
  finishConsultation,
  getCardDataOptions,
  getConsultationAnamnesis,
  getCurrentConsultation,
  postConsultationAnamnesis,
} from "../../../redux/consultations/actions";
import { getCurrentConsultCardData } from "../../../redux/consultations/selectors";
import {
  resetInitialValues,
  setInitalValues,
} from "../../../redux/formInitialValues/actions";
import { getAllSpecializations } from "../../../redux/specializations/actions";
import { parserForm } from "../../../utils/parserForm";

import Anamnez from "../Anamnez/Anamnez";
import CardData from "../CardData/CardData";
import { SimpleForm } from "../formComponent";
import Toggle from "../formComponent/Toggle/Toggle";

import "./ConsultationCard.scss";

const ConsultCard = ({
  formValues = {},
  status,
  chat,
  reset,
  dirty,
  invalid,
  fillingCard,
  getCardDataOptions,
  getAllSpecializations,
  specializations,
  canselConsultation,
  postConsultationAnamnesis,
  getConsultationAnamnesis,
  finishConsultation,
  setInitalValues,
  endOfConsultation,
  history,
  setLoading,
  getCurrentConsultation,
  sendChatMessage,
  autofill,
  closedCardData,
}) => {
  const [options, setOptions] = useState();
  const [cancel, setCancel] = useState();
  const [anamnesis, setAnamnesis] = useState();
  const [saveButtonLoading, setSaveButtonLoading] = useState(false)
  const [cancelButtonLoading, setCancelButtonLoading] = useState(false)
  const { t } = useTranslation();
  const consultId = new URLSearchParams(history.location.search).get(
    "consultId"
  );
  const patientId = new URLSearchParams(history.location.search).get("user");

  useEffect(() => {
    consultId && getCurrentConsultation(consultId);
  }, []);

  useEffect(() => {
    if (consultId) {
      if (cancel) {
        setCancelButtonLoading(true)
        setLoading(true);
        canselConsultation({
          id: consultId,
        }).then(() => {
          setLoading(false);
          history.push("/");
        })
        .finally(() => setCancelButtonLoading(false))
      }
    }
  }, [cancel]);

  useEffect(() => {
    setInitalValues({
      ...formValues,
      consultCard: {
        value: "anamnez",
      },
    });
  }, []);

  useEffect(() => {
    if (consultId) {
      getCardDataOptions().then(({ res }) => setOptions(res));
      getAllSpecializations(100);
      getConsultationAnamnesis({
        id: consultId,
      }).then(({ res }) => {
        res && setAnamnesis(res.data.items);
      });
    }
  }, []);


  useEffect(() => {
    if (closedCardData) {
      Object.keys(closedCardData).forEach((key) => {
        autofill(key, closedCardData[key])
      })
    }
  }, [closedCardData])

  const formSubmit = (e) => {
    e.preventDefault();
    const { ...fields } = parserForm(e);
    const confirm = window.confirm(t("submitConfirm"));

    console.log(formValues);
    if (confirm) {
      if (!cancel) {
        setSaveButtonLoading(true)
        return fillingCard({
          id: consultId,
          data: {
            appointmentCard: {
              complaintsText: formValues.complaintsText,
              currentAnamnesisText: formValues?.currentAnamnesisText,
              allergicText: formValues?.allergicText,
              pastIllnessText: formValues?.pastIllnessText,
              treatmentPlanText: formValues?.treatmentPlanText,
              conclusionText: formValues.conclusionText,
              analysis: formValues?.analysis?.map((el) => el.id) || [],
              diagnostic: formValues?.diagnostic?.map((el) => el.id) || [],
              disease: formValues?.disease?.map((el) => el.id) || [],
              drugs: formValues?.drugs?.map((el) => el.id) || [],
              specialtyRecommend: formValues?.specializations?.length ? formValues.specializations.map((el) => el.id) : [],
            },
          },
        })
          .then((res) => {
            // debugger;

            sendChatMessage({
              type: "finished",
              text: `${t("finishConsultationMessage")}${
                formValues.conclusionText
              }`,
              some: "param",
              appointmentId: consultId,
              userId: patientId,
            });
            postConsultationAnamnesis({
              appointment_id: consultId,
              anamnezField: anamnesis,
              values: fields,
            });

            // endOfConsultation(true, formValues.conclusionText);
            finishConsultation({
              id: consultId,
            });
          })
          .then(() => reset())
          .then(() => history.push("/home/doctor/monitoring?page=1&period=All"))
          .finally(() => setSaveButtonLoading(false))
      }
    }
  };

  const classNameConsultCard =
    chat && chat.mode ? "consultCard fullscreen" : "consultCard";

  return (
    <div className={classNameConsultCard}>
      <p className="medCardTitle">{t("consultationCard")}</p>
      <SimpleForm handleSubmit={formSubmit} autoComplete="off" novalidate>
        <Field
          name="consultCard"
          component={Toggle}
          options={[
            { value: "anamnez", text: t("anamnez") },
            { value: "cardData", text: t("cardData") },
          ]}
        />
        <div className="consultCartFormContent">
          <div className="formContent">
            <div
              className={`dd1 ${
                formValues.consultCard &&
                formValues.consultCard.value === "anamnez"
                  ? ""
                  : "none"
              }`}
            >
              <Anamnez
                // formSubmit={formSubmit}
                formValues={formValues}
                anamnezFields={anamnesis}
                setInitalValues={setInitalValues}
                status={status}
              />
            </div>
            <div
              className={`dd2 ${
                formValues.consultCard &&
                formValues.consultCard.value === "cardData"
                  ? ""
                  : "none"
              }`}
            >
              {options && (
                <CardData
                  formValues={formValues}
                  disease={options.disease}
                  specializations={specializations}
                  diagnostic={options.diagnostic}
                  analysis={options.analysis}
                  drugs={options.drugs}
                  dirty={dirty}
                  invalid={invalid}
                  cansel={cancel}
                  setCancel={setCancel}
                  saveButtonLoading={saveButtonLoading}
                  cancelButtonLoading={cancelButtonLoading}
                />
              )}
            </div>
          </div>
        </div>
      </SimpleForm>
    </div>
  );
};

const mapDispatchToProps = {
  setInitalValues,
  resetInitialValues,
  getCardDataOptions,
  getAllSpecializations,
  getConsultationAnamnesis,
  fillingCard,
  canselConsultation,
  postConsultationAnamnesis,
  finishConsultation,
  getCurrentConsultation,
  sendChatMessage,
};

const mapStateToProps = (state) => ({
  formValues: getFormValues("consultationCardForm")(state),
  initialValues: state.formInitialValues,
  specializations: state.specialization && state.specialization.items,
  appointments: state.patients && state.patients.appointments,
  status: state.monitoring?.consultations?.currentConsultation?.status,
  closedCardData: getCurrentConsultCardData(state),
  chat: state.chat,
});

const ConsultationCard = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "consultationCardForm", enableReinitialize: true }),
  withRouter
);

export default ConsultationCard(ConsultCard);
